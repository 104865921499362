import React, { useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import ProgressSpinner from "@components/ProgressSpinner";

import { SurveyForm } from "../../../../components/Survey";
import CreateProductConfig from "./CreateProductConfig.json";
import createNewProduct from "@app/services/services/createNewProduct";
import { useToast } from "@hooks/useToast";
import { preparePayload } from "./utils";

const CreateServiceDialog = ({ onClose }) => {
  const { messages } = useIntl();
  const surveyRef = useRef(null);
  const { showSuccessToast, showErrorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    service_type: "",
    analysis_type: "",
    data_source: [],
    child: [],
    name: "",
    price: "",
    delivery_time: "",
    listing_order: null,
    sku: "",
    description: "",
    default_selected: false,
    default_available: false,
    purchase_by_credits: false,
    price_credits: 0,
    state: "active",
    type: "service",
  };

  const handleUpdateProduct = useCallback(async data => {
    try {
      setIsLoading(true);
      const payload = preparePayload(data);
      const finalData = {
        ...payload,
      };

      await createNewProduct(finalData);
      showSuccessToast(messages.product_updated_successfully);
      onClose();
    } catch (e) {
      showErrorToast(e?.response?.data?.message ?? messages.text_no_data_found);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};
    handleUpdateProduct(data);
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog title={messages.title_create_service} onClose={onClose}>
        <SurveyForm
          data={initialValues}
          formConfig={CreateProductConfig}
          onRefLoaded={handleLoadSurveyRef}
          onComplete={handleSave}
          showCompleteButton={true}
          onClose={onClose}
        />
      </FullSizeDialog>
    </Div>
  );
};

CreateServiceDialog.propTypes = {
  onClose: PropTypes.func,
};

export default CreateServiceDialog;
