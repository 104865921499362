import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import DataTable from "@components/DataTableV2/DataTable";
import { H2 } from "@components/Heading";

import ActivityLogDetails from "../../../admin/activityLogs/Details";

const SubscriptionActivityLog = ({ activityLogData }) => {
  const { messages } = useIntl();

  const [selectedActivityLog, setSelectedActivityLog] = useState(null);

  const handleOpenActivityLogDetailsDialog = values => {
    setSelectedActivityLog(values);
  };

  const handleCloseActivityLogDetailsDialog = () => {
    setSelectedActivityLog(null);
  };

  const formatUser = (value = {}) => {
    const { name = "" } = value || {};
    return name || "-";
  };

  const formatAuditableType = value => {
    const model = value?.split("App\\Models\\")?.[1] || "";
    const result = String(model ? messages[model.toLowerCase()] : "-");
    return result;
  };

  const activityConfig = {
    headerActions: [],
    no_records_message: "no_payment_received_yet",
    default_sort: {
      field: "created_at",
      order: "-1",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
      },
      {
        title: "label_purchaser",
        db_field: "user",
        type: "text",
        formatter: formatUser,
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
      },
      {
        title: "type_label",
        db_field: "event",
        type: "enum",
      },
      {
        title: "model",
        db_field: "auditable_type",
        type: "text",
        formatter: formatAuditableType,
      },
      {
        type: "actions",
        width: "50px",
        icon: "menu-report",
        actions: [
          {
            icon: "menu-report",
            type: "button",
            onClick: handleOpenActivityLogDetailsDialog,
          },
        ],
      },
    ],
    backend_querying: false,
    enable_pagination: true,

    static_data: activityLogData,
  };

  return (
    <>
      {selectedActivityLog && (
        <ActivityLogDetails
          data={selectedActivityLog}
          handleClose={handleCloseActivityLogDetailsDialog}
        />
      )}

      <H2 >{messages.subscription_activity_log}</H2>

      <DataTable config={activityConfig} />
    </>
  );
};

SubscriptionActivityLog.propTypes = {
  activityLogData: PropTypes.array,
};

export default SubscriptionActivityLog;
