import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import styled from "styled-components";
import Proptypes from "prop-types";
import Div from "@components/Div";
import Logo from "@components/Logo";
import LogoFull from "@assets/logo.png";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import { MYACCOUNTS_NAV_SIDEBAR, INDIVIDUAL, COMPANY } from "@utils/constant";
import Config from "@src/config";
import { TextMediumWeight } from "@components/Text";
import useAuthorization from "@hooks/useAuthorization";
import SideMenuContent from "./SideMenuContent";
import { BACKGROUND_SE } from "../utils/constant";

export const StyledDiv = styled(Div)`
  display: flex;
`;
const handleRedirect = () => {
  window.location = " /sok";
};

const LogoComponent = () => (
  <Div mt={3} mb={4}>
    <Logo
      display="block"
      logo={LogoFull}
      width={140}
      height={"auto"}
      mx={3}
      py={4}
      onClick={handleRedirect}
    />
  </Div>
);

export const CustomerLayout = ({ children }) => {
  const { messages } = useIntl();
  const { hasAnyPermissions } = useAuthorization();
  const { userInfo = {} } = useSelector(state => state.authReducer);
  const { customer = {} } = userInfo;
  const { type = INDIVIDUAL, prepaid_account: isPrepaidUser } = customer;

  let myAccountsNav = MYACCOUNTS_NAV_SIDEBAR.filter(i => {
    if (type === INDIVIDUAL) {
      if (isPrepaidUser === 1) {
        return i.label !== "FÖRETAGSINFO" && i.label !== "ANVÄNDARE";
      } else {
        return !(
          i.label === "ABONNEMANG" ||
          i.label === "FÖRETAGSINFO" ||
          i.label === "ANVÄNDARE"
        );
      }
    }
    if (type === COMPANY && isPrepaidUser === 0) {
      return i.label !== "ABONNEMANG";
    }
    return i;
  });

  if (customer.status !== "active") {
    myAccountsNav = myAccountsNav.filter(i => i.label !== "BEVAKNING");
  }

  let menuItems = myAccountsNav.filter(obj => {
    const { permissions = [] } = obj;
    return hasAnyPermissions(permissions);
  });

  const prop = {
    Component: LogoComponent,
    navItems: [...menuItems],
  };

  return (
    <Div display="flex" maxWidth="100vw" overflow="hidden">
      <Div
        width={[1, 1, 1, "192px"]}
        minHeight="100vh"
        minWidth={192}
        display={["none", "none", "none", "block"]}
        border={"2px solid var(--grey-lightest)"}
      >
        <Div
          position="fixed"
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <SideMenuContent {...prop} />
          <Div mb="22px">
            <LinkArrow
              height={44}
              px={2}
              display={"flex"}
              href={`${Config.WP_URL}/kontakta-oss/`}
              target="_blank"
              rel="noreferrer"
            >
              <TextMediumWeight
                wordBreak="break-all"
                fontSize={"var(--fs-text-small) !important"}
              >
                {messages.support}
              </TextMediumWeight>
            </LinkArrow>
            <LinkArrow
              height={46}
              px={2}
              display={"flex"}
              href={`${BACKGROUND_SE}`}
              target="_blank"
              rel="noreferrer"
            >
              <TextMediumWeight
                wordBreak="break-all"
                fontSize={"var(--fs-text-small) !important"}
              >
                {messages.ba_website}
              </TextMediumWeight>
              <Icon name="Arrow-diagonal" ml={2} />
            </LinkArrow>
          </Div>
        </Div>
      </Div>
      <Div
        width={[
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw - 192px)",
        ]}
        maxWidth={[
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw - 192px)",
        ]}
        backgroundColor={"var(--site-background-color)"}
      >
        {children}
      </Div>
    </Div>
  );
};

CustomerLayout.propTypes = {
  children: Proptypes.node,
};

export default CustomerLayout;
