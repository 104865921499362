import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { PrimaryButtonIconOutlined } from "@components/Button";
import ConfirmationPopup from "@components/ConfirmationPopup";
import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import Icon from "@components/Icon";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";

import { AdminPermissions } from "@src/enum/Permissions";

import useProductDetails from "./useProductDetails";

const DetailsTab = ({
  data,
  dataSourceTypes,
  legalFrameworks,
  onUpdateService,
}) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { id: productId } = useParams();
  const {
    config: { details, description, configuration },
    hasPermission,
    isLoading,
    productData,
    handleUpdateService,
    handleUpdateConfiguration,
    handleDeleteSubmit,
  } = useProductDetails({
    productId,
    data,
    dataSourceTypes,
    legalFrameworks,
    onUpdateService,
  });

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const hasDeleteAccessToProducts = hasAllPermissions([
    AdminPermissions.AdminDeleteProducts,
  ]);

  const handleDeleteProduct = () => {
    setIsDeleteDialogVisible(true);
  };

  const handleCancelDeleteProduct = () => {
    setIsDeleteDialogVisible(false);
  };

  return (
    <Div
      width={[1, 1, 1, 800]}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
    >
      {isLoading && <ProgressSpinner />}

      <EditableFieldsCard
        module="product"
        title={messages.label_details}
        config={details}
        data={productData}
        onSubmit={handleUpdateService}
        isEditPermission={hasPermission}
      />

      <EditableFieldsCard
        module="product"
        title={messages.title_product_description}
        config={description}
        data={productData}
        onSubmit={handleUpdateService}
        isEditPermission={hasPermission}
      />

      <EditableFieldsCard
        module="product"
        title={messages.title_configuration}
        config={configuration}
        data={productData}
        onSubmit={handleUpdateConfiguration}
        isEditPermission={hasPermission}
      />

      {hasDeleteAccessToProducts && (
        <Div mt={2} width={1}>
          <PrimaryButtonIconOutlined
            color="red"
            width={[1, 1, "250px", "250px"]}
            margin={3}
            label={messages.label_delete_product}
            onClick={handleDeleteProduct}
            icon={<Icon mr={2} name="rubber" />}
          />

          {isDeleteDialogVisible && (
            <ConfirmationPopup
              title={messages.label_delete_product}
              description={messages.product_delete_message}
              onCancelClick={handleCancelDeleteProduct}
              onAcceptClick={handleDeleteSubmit}
            />
          )}
        </Div>
      )}
    </Div>
  );
};

DetailsTab.propTypes = {
  data: PropTypes.object,
  dataSourceTypes: PropTypes.array,
  legalFrameworks: PropTypes.array,
  onUpdateService: PropTypes.func,
};

export default DetailsTab;
