import React from "react";
import PropTypes from "prop-types";

import FullSizeDialog from "@components/FullSizeDialog";

import ReportPreview from "./ReportPreview";

const ReportPreviewDialog = ({ reportConfig, reportData, onClose }) => {
  return (
    <FullSizeDialog onClose={onClose}>
      <ReportPreview reportConfig={reportConfig} reportData={reportData} />
    </FullSizeDialog>
  );
};

ReportPreviewDialog.propTypes = {
  reportConfig: PropTypes.object,
  reportData: PropTypes.object,
  onClose: PropTypes.func,
};

export default ReportPreviewDialog;
