import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";

import { slk, Serializer } from "survey-core";
import { editorLocalization } from "survey-creator-core";
import { registerCustomFunctions } from "./components/CustomFunctions";

import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-creator-core/i18n/swedish";

import Config from "@src/config";

import { registerButton, registerQuillRichEditor } from "./components";

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
};

slk(Config.SURVEY_JS_API_KEY);
editorLocalization.currentLocale = Config.language;

registerQuillRichEditor();
registerButton();
registerCustomFunctions();

const applyHtml = (_, options) => {
  let str = options.text;

  if (str.indexOf("<p>") === 0) {
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
  }

  options.html = str;
};

const CustomFields = [
  {
    className: "panel",
    name: "baClassName",
    type: "string",
    displayName: "Class Name",
    category: "BA",
    visibleIndex: 0,
  },
  {
    className: "question",
    name: "baClassName",
    type: "string",
    displayName: "Class Name",
    category: "BA",
    visibleIndex: 0,
  },
  {
    className: "question",
    name: "isHidden",
    type: "expression",
    category: "BA",
    visibleIndex: 1,
  },
  {
    className: "comment",
    name: "isHTML",
    type: "boolean",
    category: "BA",
    visibleIndex: 2,
  },
  {
    className: "text",
    name: "isHTML",
    type: "boolean",
    category: "BA",
    visibleIndex: 2,
  },
  {
    className: "html",
    name: "isHTML",
    type: "boolean",
    category: "BA",
    visibleIndex: 2,
  },
  {
    className: "rich_content_editor",
    name: "isHTML",
    type: "boolean",
    category: "BA",
    visibleIndex: 2,
  },
  {
    className: "paneldynamic",
    name: "htmlTemplate",
    category: "BA",
    type: "text",
    default: "",
  },
  {
    className: "paneldynamic",
    name: "targetField",
    category: "BA",
    type: "string",
    default: "",
  },
  {
    className: "paneldynamic",
    name: "htmlTemplate",
    category: "BA",
    type: "text",
    default: "",
  },

  {
    className: "survey",
    name: "dynamicFieldMappings:DynamicFieldMappings[]",
    category: "BA",
    default: [],
  },
  {
    className: "panel",
    name: "buttonText",
    typ: "string",
    default: "",
  },
];

Serializer.addClass(
  "DynamicFieldMappings", // New class name
  [
    { name: "htmlTemplate", type: "text", default: "" },
    { name: "sourceFields", type: "string", default: "" },
    { name: "targetField", type: "string", default: "" },
  ],
  null,
  "itemvalue" // Base class remains the same
);

CustomFields.forEach(field => {
  const { className, ...rest } = field;
  Serializer.addProperty(className, rest);
});

const SurveyBuilder = ({ data, activeTab, onUpdate, onModify }) => {
  const creatorRef = useRef(new SurveyCreator(creatorOptions));

  const saveSurveyJson = useCallback(
    json => {
      onUpdate(json);
    },
    [onUpdate]
  );

  const handleModified = useCallback(() => {
    onModify?.();
  }, [onModify]);

  const setupCustomComponents = useCallback(() => {
    creatorRef.current.survey.onTextMarkdown.add(applyHtml);
    creatorRef.current.onSurveyInstanceCreated.add((_, options) => {
      if (options.area === "designer-tab" || options.area === "preview-tab") {
        options.survey.onTextMarkdown.add(applyHtml);
      }
    });
  }, []);

  const setupSurveyBuilder = useCallback(() => {
    if (data) {
      creatorRef.current.text = data;
    }

    creatorRef.current.saveSurveyFunc = () => {
      saveSurveyJson(creatorRef.current.JSON);
    };

    creatorRef.current.activeTab = activeTab;
    creatorRef.current.onModified?.add(handleModified);

    setupCustomComponents();
  }, [activeTab, data, saveSurveyJson, handleModified, setupCustomComponents]);

  useEffect(() => {
    setupSurveyBuilder();
  }, [setupSurveyBuilder]);

  return <SurveyCreatorComponent creator={creatorRef.current} />;
};

SurveyBuilder.propTypes = {
  data: PropTypes.string,
  activeTab: PropTypes.string,
  onUpdate: PropTypes.func,
  onModify: PropTypes.func,
};

export default SurveyBuilder;
