import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { ButtonLinkIcon } from "@components/Button";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { LinkV2 } from "@components/Link";
import { Text, TextMediumWeight } from "@components/Text";

import useAuthorization from "@hooks/useAuthorization";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

const ReportStateMessageComponent = ({
  isReportCreated,
  reportId,
  processType,
  sourceDataStatus,
  onRecreateReport,
}) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();

  const hasWriteReportAnalysisPermission = hasAllPermissions([
    AdminPermissions.AdminWriteReportAnalysis,
  ]);

  const renderText = () => {
    const notReadyText =
      messages[`text_case_report_${processType}_${sourceDataStatus}`];
    const valueToPresent = String(
      isReportCreated ? messages.text_case_report_created : notReadyText
    );

    return valueToPresent;
  };

  return (
    <Div
      className="report-status-container"
      borderColor={"var(--turquoise)"}
      borderWidth="1px"
      borderStyle="dashed"
      mt={3}
      p={3}
      mb={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Div display="flex" alignItems="center" gridGap={2}>
        <Icon
          name="sign"
          fontSize="var(--fs-icon-m)"
          color="var(--white)"
          backgroundColor={isReportCreated ? "var(--blue-dark)" : "var(--red)"}
          p={1}
          borderRadius={"50%"}
        />
        <Text fontSize="var(--fs-text-m) !important">{renderText()}</Text>

        {isReportCreated && (
          <LinkV2 to={ROUTES.ANALYS_DETAILS.URL.replace(":id", reportId)}>
            <TextMediumWeight>{messages.label_report}</TextMediumWeight>
            <Icon name="headerarrowright" ml={1} color="var(--turquoise)" />
          </LinkV2>
        )}
      </Div>

      {isReportCreated && hasWriteReportAnalysisPermission && (
        <ButtonLinkIcon
          label={messages.label_re_create_report}
          icon={
            <Icon
              mr={1}
              name="restart"
              fontSize="var(--fs-icon-m)"
              color="var(--turquoise)"
            />
          }
          onClick={onRecreateReport}
        />
      )}
    </Div>
  );
};

ReportStateMessageComponent.propTypes = {
  isReportCreated: PropTypes.bool,
  reportId: PropTypes.string,
  processType: PropTypes.string,
  sourceDataStatus: PropTypes.string,
  onRecreateReport: PropTypes.func,
};

export default ReportStateMessageComponent;
