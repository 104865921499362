import React, { useState, useEffect, memo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

import hash from "object-hash";

import createShareLinkService from "@app/services/reports/createShareLinkService";

import LogoFull from "@assets/logo.png";

import Div from "@components/Div";
import { H1, H2 } from "@components/Heading";
import Icon from "@components/Icon";
import Logo from "@components/Logo";
import ProgressSpinner from "@components/ProgressSpinner";

import { useToast } from "@hooks/useToast";

import Config from "@src/config";

const Frame = styled.div`
  background-color: white;
  position: absolute;
  top: -999999px;
  left: 0px;
  right: 0px;
  overflow: auto;
  height: 100vh;
  max-width: 1174px;
`;

import {
  CopyLinkDialog,
  DownloadGDPRDialog,
  GenerateLinkDialog,
} from "./components";
import { ELEMENTS_BY_TYPE, generateReportPDF } from "./utils";

import PrintReportDialog from "../LegacyReport/PrintReportDialog";
import useAuthorization from "@hooks/useAuthorization";
import { CustomerPermissions, AdminPermissions } from "@src/enum/Permissions";

const CaseReport = ({ report, hideIcons }) => {
  const { hasAnyPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { pathname } = useLocation();
  const { showErrorToast } = useToast();

  const [showGDPRForDownload, setShowGDPRForDownload] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [isGenerateLinkDialogVisible, setIsGenerateLinkDialogVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");

  const hasPDFPermission = hasAnyPermissions([
    CustomerPermissions.CustomerDownloadReport,
    AdminPermissions.AdminReadReportAnalysis,
  ]);

  const {
    report_config: config,
    report_data: data = {},
    id: report_id = "",
  } = report;
  const { pages = [] } = config;

  useEffect(() => {
    const handleMessage = event => {
      const allowedOrigin = window.location.origin; // Parent domain
      if (event.origin !== allowedOrigin) {
        return;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const generateShareLink = async () => {
    try {
      setIsLoading(true);

      const { data: { token = "" } = {} } =
        await createShareLinkService(report_id);

      if (!token) {
        throw new Error("No search link token provided");
      }

      const params = new URLSearchParams();
      params.append("id", report_id);
      params.append("token", token);

      const constructedShareLink = `${Config.APP_URL}${pathname}?${params.toString()}`;

      setShareLink(constructedShareLink);
    } catch {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmPopup = async () => {
    setShowGDPRForDownload(false);
    setGeneratingPDF(true);
    setTimeout(async () => {
      const today = new Date().toLocaleDateString("sv-SE");
      const fileName = `${config?.title}-${data?.name}-${today}.pdf`;
      await generateReportPDF(fileName, ".report-page", true);
      setGeneratingPDF(false);
    }, 300);
  };

  const handleDownloadReport = () => {
    setShowGDPRForDownload(true);
  };

  const handleClose = () => {
    setShowGDPRForDownload(false);
  };

  const handleOpenGenerateLinkDialog = () => {
    setIsGenerateLinkDialogVisible(true);
  };

  const handleCloseGenerateLinkDialog = () => {
    setIsGenerateLinkDialogVisible(false);
  };

  const handleOpenCopyLinkDialog = () => {
    handleCloseGenerateLinkDialog();

    generateShareLink();
  };

  const handleCloseCopyLinkDialog = () => {
    setShareLink("");
  };

  const renderPage = (page, isPDF = false) => {
    const { elements: pageElements = [] } = page;
    return (
      <Div display="flex" flexDirection="column" gridGap={4}>
        {pageElements
          ?.filter(
            ({ elements = [] }) =>
              elements.length === 0 ||
              !elements.every(({ isHidden }) => isHidden)
          )
          .map(element => {
            const elementToRender = ELEMENTS_BY_TYPE[element?.type];
            const props = {
              ...element,
              key: `${element?.type}_${element.name}${hash(element)}`,
              isPDF,
            };
            return elementToRender?.(props, data);
          })}
      </Div>
    );
  };

  const renderHeader = (isPDF = false) => (
    <>
      {isPDF && (
        <H2 color="var(--blue-dark) !important">{messages.label_report}</H2>
      )}
      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        alignItems={["flex-start", "flex-start", "center", "center"]}
        justifyContent="space-between"
        gridGap={[3, 3, 0, 0]}
        mb={4}
      >
        <H1>{config.title}</H1>

        {!hideIcons && (
          <Div className="report-icons" display="flex" gridGap={3}>
            {hasPDFPermission && (
              <Icon
                p={2}
                name="download-assignment"
                color="var(--turquoise)"
                cursor="pointer"
                fontSize="var(--fs-icon-m)"
                backgroundColor="var(--site-background-color)"
                borderRadius="10px"
                onClick={handleDownloadReport}
              />
            )}

            <Icon
              p={2}
              name="share"
              color="var(--turquoise)"
              cursor="pointer"
              fontSize="var(--fs-icon-m)"
              backgroundColor="var(--site-background-color)"
              borderRadius="10px"
              onClick={handleOpenGenerateLinkDialog}
            />
          </Div>
        )}

        <Div className="report-logo">
          <Logo logo={LogoFull} width="auto" maxHeight="60px" />
        </Div>
      </Div>
    </>
  );

  return (
    <>
      <Div
        id="report"
        display="flex"
        flexDirection="column"
        gridGap={4}
        p={[3, 3, 4, 4]}
        mx={[-16, -16, 0, 0]}
      >
        {(isLoading || generatingPDF) && <ProgressSpinner />}

        {showGDPRForDownload && (
          <DownloadGDPRDialog
            onClose={handleClose}
            onConfirm={handleConfirmPopup}
          />
        )}

        {isGenerateLinkDialogVisible && (
          <GenerateLinkDialog
            onCancel={handleCloseGenerateLinkDialog}
            onConfirm={handleOpenCopyLinkDialog}
          />
        )}

        {shareLink && (
          <CopyLinkDialog
            link={shareLink}
            onClose={handleCloseCopyLinkDialog}
          />
        )}

        {pages.map((page, index) => (
          <div key={`report-page-${hash(page)}`}>
            {index === 0 && renderHeader()}
            {renderPage(page)}
          </div>
        ))}

        {showGDPRForDownload && (
          <PrintReportDialog
            onClose={handleClose}
            onConfirm={handleConfirmPopup}
          />
        )}

        {generatingPDF && (
          <Frame>
            <Div
              className={"report-pdf"}
              display="flex"
              flexDirection="column"
              gridGap={4}
              p={[3, 3, 4, 4]}
              mx={[-16, -16, 0, 0]}
            >
              {pages.map((page, index) => (
                <div
                  key={`report-pdf-page-${hash(page)}`}
                  className="report-page"
                >
                  {index === 0 && renderHeader(true)}
                  {renderPage(page, true)}
                </div>
              ))}
            </Div>
          </Frame>
        )}
      </Div>
    </>
  );
};

CaseReport.propTypes = {
  report: PropTypes.object.isRequired,
  hideIcons: PropTypes.bool,
};

export default memo(CaseReport);
