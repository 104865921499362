import config from "@src/config";
import { useIntl } from "react-intl";

const useCrimeConfig = () => {
  const { messages } = useIntl(); 

  return {
    "title": "Add Crome",
    "logoPosition": "right",
    "pages": [
      {
        "name": "crime",
        "elements": [
          {
            "type": "panel",
            "name": "question1",
            "title": messages.label_crime_info,
            "elements": [
              {
                "type": "text",
                "name": "name",
                "title": messages.label_crime_name,
                "hideNumber": true,
                "isRequired": true
              },
              {
                "type": "dropdown",
                "name": "type",
                "startWithNewLine": false,
                "title": messages.type,
                "hideNumber": true,
                "isRequired": true,
                "choices": [
                  {
                    "value": "B",
                    "text": "B "
                  },
                  {
                    "value": "SF",
                    "text": "SF"
                  }
                ]
              },
              {
                "type": "dropdown",
                "name": "category",
                "title": messages.category_label,
                "hideNumber": true,
                "isRequired": true,
                "choices": [
                  "Alkohollagen",
                  "Allmänfarliga brott",
                  "Arbetsrättsliga brott",
                  "Ärekränkning",
                  "Bedrägeri och annan oredlighet",
                  "Bidragsbrott",
                  "Brott mot allmän ordning",
                  "Brott mot allmän verksamhet m.m.",
                  "Brott mot borgenärer m.m.",
                  "Brott mot familj",
                  "Brott mot fiskelagen och djurskyddslagen",
                  "Brott mot folkbokföringslagen",
                  "Brott mot frihet och frid",
                  "Brott mot jordabalken",
                  "Brott mot läkemedelslagen",
                  "Brott mot liv och hälsa",
                  "Brott mot mänskligheten",
                  "Brott mot skyddslagen",
                  "Brott mot Sveriges säkerhet",
                  "Dataskyddsrättsliga brott",
                  "Djurskyddslagen",
                  "Dopningsbrott",
                  "Ekonomiska brott",
                  "Ekonomiska och näringslivsrelaterade brott",
                  "Förbud mot vissa radar- och laserinstrument",
                  "Fordonsförordning",
                  "Förfalskningsbrott",
                  "Förskingring, annan trolöshet och mutbrott",
                  "Grov misshandel",
                  "Grovt narkotikabrott",
                  "Immaterialrättsliga brott",
                  "Infrastruktursrelaterade brott",
                  "Jaktlagen",
                  "Kontaktförbud",
                  "Körkortslagen/Fordons användning/Trafikförordningen",
                  "Lag om förbud beträffande knivar och andra farliga föremål",
                  "Lag om tobak och liknande produkter",
                  "Lag om totalförsvarsplikt",
                  "Mened, falskt åtal och annan osann utsaga",
                  "Migrationsbrott",
                  "Miljö-, djurskyddsbrott m.m.",
                  "Misshandel",
                  "Misshandel, ringa brott",
                  "Mord",
                  "Narkotikabrott",
                  "Ordningsbrott",
                  "Ordningslagen",
                  "Övriga brott",
                  "Penningtvättsbrott",
                  "Processrättsliga brott",
                  "Rån",
                  "Ringa narkotikabrott",
                  "Sexualbrott",
                  "Sjöfartsbrott",
                  "Sjölagen",
                  "Skadegörelsebrott",
                  "Skattebrott",
                  "Smugglingsbrott",
                  "Snatteri",
                  "Stöld",
                  "Stöld, rån och andra tillgreppsbrott",
                  "Tjänstefel",
                  "Trafik-, fordondsrelaterade brott",
                  "Tryckfrihetsrelaterade brott",
                  "Upphovsrättsrelaterade brott",
                  "Vägtrafikregisterlagen",
                  "Vägtrafikskattelagen",
                  "Vapenbrott",
                  "Vård- och hälsorelaterade brott"
              ]
              },
              {
                "type": "text",
                "name": "points",
                "startWithNewLine": false,
                "title": messages.label_points,
                "hideNumber": true,
                "isRequired": true
              },
              {
                "type": "tagbox",
                "name": "product_id",
                "title": messages.label_products,
                "hideNumber": true,
                "errorLocation": "bottom",
                "choicesByUrl": {
                  url: `${config.BASE_URL}products?filter[type]=service&customer_id=&filter[state]=active`,
                  path: "data",
                  valueName: "id",
                  titleName: "name"
                }
              },
              {
                "type": "text",
                "name": "time_relevance",
                "title": messages.label_time_relevance,
                "hideNumber": true,
                "isRequired": true
              },
              {
                "type": "text",
                "name": "points_role_analysis",
                "startWithNewLine": false,
                "title": messages.label_roles_points,
                "hideNumber": true
              },
              {
                "type": "text",
                "name": "time_relevance_role_analysis",
                "title": messages.label_roles_time_relevance,
                "hideNumber": true
              },
              {
                "type": "text",
                "name": "crime_sentence",
                "startWithNewLine": false,
                "title": messages.label_crime_sentence,
                "hideNumber": true
              }
            ]
          }
        ]
      }
    ],
    "showTitle": false
  }
};

export default useCrimeConfig;