import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import fetchAllProducts from "@app/services/services/fetchProductById";
import getDataSources from "@app/services/products/getDataSources";
import getProductById from "@app/services/services/getProductById";
import updateProductById from "@app/services/services/updateProductById";

import Breadcrumb from "@components/Breadcrumb";
import ProgressSpinner from "@components/ProgressSpinner";
import TabsNavigator from "@components/TabsNavigator";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import AdminContainer from "@layout/AdminContainer";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

import DetailsTab from "./tabs/DetailsTab";
import FormsTab from "./tabs/FormsTab";
import RulesTab from "./rules/rulesTab";

const UpdateService = () => {
  const { hasAnyPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { id: productId = "" } = useParams();
  const { showErrorToast } = useToast();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceTypes, setDataSourceTypes] = useState([]);
  const [legalFrameworks, setLegalFrameworks] = useState([]);

  const hasReadFormConfigPermission = hasAnyPermissions([
    AdminPermissions.AdminReadFormConfig,
    AdminPermissions.AdminReadReportConfig,
  ]);
  const hasReadRulesPermission = hasAnyPermissions([
    AdminPermissions.AdminReadRules,
  ]);

  const breadCrumbItems = [
    {
      label: messages.label_products,
      url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    },
    {
      label: data?.name ?? "",
    },
  ];

  const formatAndSetData = useCallback(
    (
      responseData = {},
      dataSources = dataSourceTypes,
      legalFramework = legalFrameworks
    ) => {
      const formattedResponse = {
        ...responseData,
        default_selected: !!responseData.default_selected,
        default_available: !!responseData.default_available,
        purchase_by_credits: !!responseData.purchase_by_credits,
        state: responseData.state ?? "",
        service_type: responseData.service_type ?? "",
        analysis_type: responseData.analysis_type ?? "",
        data_source: responseData.data_source
          ? dataSources?.filter(o => responseData.data_source?.includes(o.code))
          : [],
        legal_framework: responseData.legal_framework
          ? legalFramework?.filter(o =>
              responseData.legal_framework?.includes(o.code)
            )
          : [],
        child: responseData.child,
      };

      setData(formattedResponse);
    },
    [dataSourceTypes, legalFrameworks]
  );

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true);

      try {
        const [
          { data: dataSources = [] },
          response,
        ] = await Promise.all([
          getDataSources(),
          fetchAllProducts(),
          getProductById(productId),
        ]);

        const formattedDataSources = dataSources.map(dataSource => ({
          name: messages[`datasource_${dataSource}`],
          code: dataSource,
        }));
        const legalFrameworkOptions = [
          "gdpr",
          "publishing_certificate",
          "security_protection_act",
        ];
        const formattedLegalFramework = legalFrameworkOptions.map(
          legalFramework => ({
            name: messages[`legalFramework_${legalFramework}`],
            code: legalFramework,
          })
        );

        const {
          data: { data: respData = [] },
        } = response;
        const selectedService =
          respData.find(({ id }) => id === Number(productId)) || {};

        setDataSourceTypes(formattedDataSources);
        setLegalFrameworks(formattedLegalFramework);
        formatAndSetData(
          selectedService,
          formattedDataSources,
          formattedLegalFramework
        );
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateService = useCallback(
    async payload => {
      try {
        setIsLoading(true);
        const numericValue =
          typeof payload?.price === "string"
            ? Number(payload.price.replace(/\D/g, ""))
            : Number(payload?.price);

        const formattedPayload = Object.keys(payload).reduce((acc, key) => {
          if (data[key] !== payload[key]) {
            acc[key] = payload[key];
          }

          return acc;
        }, {});
        formattedPayload.id = productId;
        if(numericValue){
          formattedPayload.price = numericValue;
        }

        if (payload?.data_source) {
          formattedPayload.data_source = payload?.data_source?.map(o => o);
        }
        if (payload?.legal_framework) {
          formattedPayload.legal_framework = payload?.legal_framework?.map(
            o => o
          );
        }
        if (payload?.child) {
          formattedPayload.child = payload?.child?.map(o => o);
        }

        const { data: response = {} } =
          await updateProductById(formattedPayload);

        formatAndSetData(response);
      } catch (e) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [
      data,
      formatAndSetData,
      messages.exception_error_message,
      productId,
      showErrorToast,
    ]
  );

  const handleUpdateServiceWithFormData = useCallback(
    payload => {
      const formattedPayload = {
        ...payload,
        id: productId,
      };

      handleUpdateService(formattedPayload);
    },
    [handleUpdateService, productId]
  );

  const tabsConfigData = useMemo(
    () => ({
      customer_form: data.customer_form,
      candidate_form: data.candidate_form,
      report_config: data.report_config,
    }),
    [data]
  );

  const tabContent = useMemo(
    () => [
      {
        title: messages.title_service_info,
        content: (
          <DetailsTab
            data={data}
            dataSourceTypes={dataSourceTypes}
            legalFrameworks={legalFrameworks}
            onUpdateService={handleUpdateService}
          />
        ),
        url: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT.URL.replace(":id", productId),
      },
      {
        title: messages.form_configuration,
        content: (
          <FormsTab
            formsData={tabsConfigData}
            onUpdateService={handleUpdateServiceWithFormData}
          />
        ),
        url: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT_FORM_CONFIGURATION.URL.replace(
          ":id",
          productId
        ),
        isHidden: !hasReadFormConfigPermission || !data?.is_case,
      },
      {
        title: messages.title_rules,
        content: <RulesTab />,
        url: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT_RULES.URL.replace(
          ":id",
          productId
        ),
        isHidden: !hasReadRulesPermission,
      },
    ],
    [
      data,
      dataSourceTypes,
      legalFrameworks,
      handleUpdateService,
      handleUpdateServiceWithFormData,
      hasReadFormConfigPermission,
      hasReadRulesPermission,
      messages.form_configuration,
      messages.title_rules,
      messages.title_service_info,
      productId,
      tabsConfigData,
    ]
  );

  const containerConfig = {
    title: data?.name,
  };

  return (
    <AdminContainer config={containerConfig}>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          <Breadcrumb items={breadCrumbItems} p={0} />
          <TabsNavigator content={tabContent} />
        </>
      )}
    </AdminContainer>
  );
};

export default UpdateService;
