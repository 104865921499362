import Axios from "@app/api/axios";
import { ROLES } from "@utils/constant";
const rolesService = (query) =>
  Axios.get(`${ROLES}?per_page=1000&${query}`); 

export const getRolesServiceById = (id) => {
  return Axios.get(`${ROLES}?filter[id]=${id}&include=permissions`)
    .then(({ data: { data } }) => { 
      const [roleData] = data; 
      return roleData; 
    })
};
export default rolesService;
