import {useState, useEffect } from "react";
import { useIntl } from "react-intl";
import permissionsService from "../../../app/services/permissions/permissionsService";
const useRoleConfig = () => {
  const { messages } = useIntl();
  const [group1, setGroup1] = useState([]);
  const [group2, setGroup2] = useState([]); 

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const rolesData = await getRolesData();
        const groupedRoles = groupRolesByGroup(rolesData);
        const { newGroup1, newGroup2 } = splitGroups(groupedRoles);
        
        setGroup1(newGroup1);
        setGroup2(newGroup2);
      } catch (error) {
        return error
      }
    };

    fetchPermissions();
  }, []);

  const getRolesData = async () => {
    const { data: { data } } = await permissionsService();
    return data;
  };

  const groupRolesByGroup = (rolesData) => {
    return rolesData.reduce((acc, role) => {
      if (!acc[role.group]) {
        acc[role.group] = [];
      }
      acc[role.group].push(role);
      return acc;
    }, {});
  };

  const splitGroups = (groupedRoles) => {
    const newGroup1 = {};
    const newGroup2 = {};
    const groupsKeys = Object.keys(groupedRoles);
    const midIndex = Math.ceil(groupsKeys.length / 2);

    groupsKeys.forEach((key, index) => {
      if (index < midIndex) {
        newGroup1[key] = groupedRoles[key];
      } else {
        newGroup2[key] = groupedRoles[key];
      }
    });

    return { newGroup1, newGroup2 };
  };

  const createTagboxesGroup1 = () => {
    return Object.keys(group1).map(group => ({
      type: "checkbox",
      name: group,
      hideNumber: true,
      title: messages[`permissions_group_${group}`],
      choices: group1[group].map((groupOptions) => ({
        value: groupOptions.id,
        text: groupOptions.name,
      })),
      showSelectAllItem: true,
    }));
  };
  const createTagboxesGroup2 = () => {
    return Object.keys(group2).map(group => ({
      type: "checkbox",
      name: group,
      hideNumber: true,
      title: messages[`permissions_group_${group}`],
      choices: group2[group].map((groupOptions) => ({
        value: groupOptions.id,
        text: groupOptions.name,
      })),
      showSelectAllItem: true,
    }));
  };
  return {
    "title": "Add Role",
    "logoPosition": "right",
    "pages": [
      {
        "name": "permission",
        "elements": [
          {
            "type": "panel",
            "name": "question1",
            "title":messages.label_details,
            "elements": [
              {
                "type": "text",
                "name": "name",
                "title": messages.label_rule_name,
                "hideNumber": true,
                "isRequired": true
              },
              {
                "type": "dropdown",
                "name": "portal",
                "startWithNewLine": false,
                "title": messages.report_portal,
                "hideNumber": true,
                "isRequired": true,
                "choices": [
                  {
                    "value": "customer",
                    "text": messages.customer
                  },
                  {
                    "value": "admin",
                    "text": messages.admin
                  }
                ]
              }
            ]
          },
          {
            "type": "panel",
            "name": "permissions",
            "title": messages.report_permissions,
            "elements": [
              {
                "type": "panel",
                "name": "permissions1",
                "elements": createTagboxesGroup1(),
              },
              {
                "type": "panel",
                "name": "permissions2",
                "startWithNewLine": false,
                "elements": createTagboxesGroup2(),
              }
            ]
          }
        ]
      }
    ],
    "showTitle": false
  };
};
export default useRoleConfig;