import React from "react";
import PropTypes from "prop-types";

import { Menu as PRMenu } from "primereact/menu";

import styled from "styled-components";
import { compose, flexbox, layout, space } from "styled-system";

const StyledMenu = styled(PRMenu)`
  ${compose(space, flexbox, layout)};

  top: 60px !important;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  width: 230px;
  position: fixed;

  .p-menuitem-link {
    &:focus {
      box-shadow: none;
    }
    width: 230px;
    text-align: left;
  }

  .p-menuitem-text {
    color: var(--grey-dark) !important;
    font-size: var(--fs-text-m);
    line-height: 22px;
    font-weight: var(--medium-weight) !important;
    width: 100%;
  }
`;

const Menu = ({ id, model, forwardRef, onHide }) => {
  return (
    <StyledMenu popup id={id} model={model} ref={forwardRef} onHide={onHide} />
  );
};

Menu.propTypes = {
  id: PropTypes.string,
  model: PropTypes.array,
  forwardRef: PropTypes.any,
  onHide: PropTypes.func,
};

export default Menu;
