import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { klarnaActions } from "@app/reducers/klarnaReducer";

import { PrimaryButton } from "@components/Button";
import ProgressSpinner from "@components/ProgressSpinner";

import { trackPageView } from "@src/analyticsFunctions";
import CreateOrder from "@src/pages/shared/CreateOrder";
import {useOrder} from "@contexts/OrderContext";
import { useStepper } from "@contexts/StepperContext";

import {
  KLARNA_CHECKOUT,
  INVOICE_CHECKOUT,
  PAYMENT_METHOD_CREDITS_AND_INVOICE,
} from "@utils/constant";

const PaymentOption = ({
  htmlSnippet,
  paymentMethod = "",
  isConsent = false,
  promoCode = "",
  billingAccountId = null,
}) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { orderUser } = useOrder();
  const { onNextStep } = useStepper();

  const [isLoading, setIsLoading] = useState(false);

  const personInfo = useSelector(
    (state) => state.personInformationReducer.personInfo,
  );

  const payload = useMemo(
    () =>
      personInfo.flatMap(
        ({
          ssn = "",
          name = "",
          phone = "",
          email = "",
          services = [],
          zipcode: zip_code = "",
          firstName: person_first_name = "",
          lastName: person_last_name = "",
          city = "",
          street = "",
        }) =>
          services.map(({ id }) => ({
            service_id: id,
            personal_number: ssn,
            person_name: name,
            phone: phone,
            email: email,
            zip_code: zip_code?.replace(/\s/g, ""),
            person_first_name,
            person_last_name,
            city: city,
            street_address: street,
          })),
      ),
    [personInfo],
  );

  const handleProcessSuccessfully = useCallback(() => {
    onNextStep();
  }, [onNextStep]);

  const processOrderResponse = useCallback(
    (response) => {
      const {
        data,
        data: {
          klarna: { original = {} },
        },
      } = response;

      dispatch(klarnaActions.klarnaInfo(original));
      dispatch(klarnaActions.ordersInfo(data));

      if (paymentMethod !== KLARNA_CHECKOUT) {
        handleProcessSuccessfully();

        return;
      }

      htmlSnippet?.(original);

      if (!original.html_snippet) {
        handleProcessSuccessfully();
      }
    },
    [dispatch, htmlSnippet, handleProcessSuccessfully, paymentMethod],
  );

  const handleCreateOrder = useCallback(async () => {
    try {
      setIsLoading(true);

      const finalPayload = {
        payload,
        props: { consent: isConsent },
        paymentMethod,
        promoCode,
        billingAccountId,
      };

      if (orderUser) {
        const { id: user_id, customer: { id: customer_id } = {} } = orderUser;

        finalPayload.userDetails = { user_id, customer_id };
      }

      const response = await CreateOrder(finalPayload);

      processOrderResponse(response);
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    billingAccountId,
    isConsent,
    payload,
    paymentMethod,
    processOrderResponse,
    promoCode,
    orderUser,
  ]);

  const handleSubmit = useCallback(() => {
    handleCreateOrder();
  }, [handleCreateOrder]);

  useEffect(() => {
    trackPageView();

    if (paymentMethod !== KLARNA_CHECKOUT) {
      return;
    }

    async function fetchData() {
      try {
        handleSubmit();
      } catch (e) {
        handleSubmit();
      }
    }

    fetchData();
  }, [handleSubmit, paymentMethod]);

  const shouldCheckForBillingAccount =
    paymentMethod === INVOICE_CHECKOUT ||
    paymentMethod === PAYMENT_METHOD_CREDITS_AND_INVOICE;

  return (
    paymentMethod !== KLARNA_CHECKOUT && (
      <>
        {isLoading && <ProgressSpinner />}

        <PrimaryButton
          px={4}
          mt={"14px"}
          width={[1, "auto"]}
          label={messages.label_create_order}
          onClick={handleSubmit}
          disabled={
            isLoading || (shouldCheckForBillingAccount && !billingAccountId)
          }
        />
      </>
    )
  );
};

PaymentOption.propTypes = {
  htmlSnippet: PropTypes.func,
  paymentMethod: PropTypes.string,
  isConsent: PropTypes.bool,
  promoCode: PropTypes.string,
  billingAccountId: PropTypes.string,
};

export default PaymentOption;
