import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import updateCaseDetailsById from "@app/services/cases/updateCaseDetailsById";

import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import {
  CASES_DATA_STATUS_TYPE,
  CASES_STATUS_TYPE,
  FORM_INPUT_TYPE,
  PROCESS_TYPE,
} from "@utils/enum";
import { formatDataStatus, formatDateAndTime } from "@utils/utils";

import AssigneeCard from "./components/AssigneeCard";

const getConfig = data => {
  const configuration = {
    generalData: [
      {
        key: "id",
        translationKey: "title_id",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.id,
      },
      {
        key: "name",
        translationKey: "label_service_name",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.order_item?.name,
        isReadOnlyField: true,
      },
      {
        key: "process_type",
        translationKey: "label_process_type",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="label_automatic" />,
            value: "automatic",
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: "manual",
          },
        ],
        selectorField: "label",
        value: data?.process_type,
      },
      {
        key: "report_status",
        translationKey: "label_report",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.report_status,
        isReadOnlyField: true,
      },
      {
        key: "created_at",
        translationKey: "created_at",
        type: FORM_INPUT_TYPE.CALENDAR,
        value: formatDateAndTime(data?.created_at),
        isReadOnlyField: true,
      },
      {
        type: FORM_INPUT_TYPE.FILLER,
      },
      {
        key: "data_sources",
        translationKey: "label_data_source",
        type: FORM_INPUT_TYPE.MULTISELECT,
        options: data?.product?.data_source,
        selectorField: "name",
        selectAllLabel: <FormattedMessage id="label_all_sources" />,
        value: data?.data_sources,
        isReadOnlyField: true,
        fullWidth: true,
      },
    ],
    statuses: [
      {
        key: "status",
        translationKey: "label_case_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: (
              <FormattedMessage
                id={`case_status_type_${CASES_STATUS_TYPE.IN_PROGRESS}`}
              />
            ),
            value: CASES_STATUS_TYPE.IN_PROGRESS,
          },
          {
            label: (
              <FormattedMessage
                id={`case_status_type_${CASES_STATUS_TYPE.COMPLETED}`}
              />
            ),
            value: CASES_STATUS_TYPE.COMPLETED,
          },
          {
            label: (
              <FormattedMessage
                id={`case_status_type_${CASES_STATUS_TYPE.CANCELLED}`}
              />
            ),
            value: CASES_STATUS_TYPE.CANCELLED,
          },
          {
            label: (
              <FormattedMessage
                id={`case_status_type_${CASES_STATUS_TYPE.PENDING}`}
              />
            ),
            value: CASES_STATUS_TYPE.PENDING,
          },
          {
            label: (
              <FormattedMessage
                id={`case_status_type_${CASES_STATUS_TYPE.MANUAL}`}
              />
            ),
            value: CASES_STATUS_TYPE.MANUAL,
          },
        ],
        selectorField: "label",
        value: data?.status,
      },
      {
        key: "data_sources_status",
        translationKey: "label_data_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="label_pending" />,
            value: CASES_DATA_STATUS_TYPE.PENDING,
          },
          {
            label: <FormattedMessage id={CASES_DATA_STATUS_TYPE.COMPLETED} />,
            value: CASES_DATA_STATUS_TYPE.COMPLETED,
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: CASES_DATA_STATUS_TYPE.MANUAL,
          },
        ],
        selectorField: "label",
        value: data?.data_sources_status,
        isReadOnlyField: true,
      },
    ],
  };

  return configuration;
};

const DetailsTab = ({ caseDetails, onCaseDetailsUpdate }) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { case_id } = useParams();
  const { showErrorToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const hasWriteCasesPermission = hasAllPermissions([
    AdminPermissions.AdminWriteCases,
  ]);

  const handleOpenErrorDialog = useCallback(() => {
    showErrorToast(messages.exception_error_message);
  }, [messages, showErrorToast]);

  const formatReport = useCallback(
    data => {
      const { report_id, is_report } = data;
      const reportString = report_id
        ? messages.report_status_completed
        : messages.label_pending;
      const result = String(
        is_report ? reportString : messages.label_no_report
      );

      return result;
    },
    [messages]
  );

  const formatData = useCallback(
    data => {
      const {
        id,
        created_at,
        status,
        source_data,
        user,
        data_sources = [],
        data_sources_status,
        order_item,
        process_type,
      } = data ?? {};

      const formattedDataSources = data_sources.map(value => ({
        name: messages[`datasource_${value}`],
      }));

      const formattedData = {
        id,
        user,
        created_at,
        status: status?.replace("-", "_"),
        data_status: formatDataStatus(source_data),
        data_sources: formattedDataSources,
        data_sources_status,
        report_status: formatReport(data),
        order_item,
        process_type,
      };

      return formattedData;
    },
    [formatReport, messages]
  );

  const handleUpdateCase = async values => {
    try {
      setIsLoading(true);

      const payload = { ...caseDetails, ...values };
      const { data: updatedCaseDetailsData } = await updateCaseDetailsById(
        case_id,
        payload
      );

      onCaseDetailsUpdate(updatedCaseDetailsData);
    } catch (error) {
      handleOpenErrorDialog();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateAssignee = event => {
    const { value } = event;
    const payload = {
      user: value,
      user_id: value.id,
    };

    handleUpdateCase(payload);
  };

  const config = useMemo(
    () => getConfig(formatData(caseDetails)),
    [formatData, caseDetails]
  );

  const shouldShowAssigneeCard = useMemo(() => {
    const { process_type = "" } = caseDetails ?? {};

    return process_type === PROCESS_TYPE.MANUAL && hasWriteCasesPermission;
  }, [caseDetails, hasWriteCasesPermission]);

  return (
    <Div display="flex" flexDirection="column">
      {isLoading && <ProgressSpinner />}

      <EditableFieldsCard
        title={messages.title_general_data}
        config={config.generalData}
      />

      {shouldShowAssigneeCard && (
        <AssigneeCard
          selectedAssignee={caseDetails.user}
          onUpdateAssignee={handleUpdateAssignee}
          onError={handleOpenErrorDialog}
        />
      )}

      <EditableFieldsCard
        title={messages.title_statuses}
        config={config.statuses}
        onSubmit={handleUpdateCase}
        isEditPermission={shouldShowAssigneeCard}
      />
    </Div>
  );
};

DetailsTab.propTypes = {
  caseDetails: PropTypes.object,
  onCaseDetailsUpdate: PropTypes.func,
};

export default DetailsTab;
