import React from "react";
import { FormattedMessage } from "react-intl";

export const QUESTION_DIALOG_TYPE = {
  CREATE: "create",
  RE_CREATE: "re_create",
  NONE: "none",
};

export const QUESTION_DIALOG_CONTENT_TYPE = {
  [QUESTION_DIALOG_TYPE.CREATE]: {
    title: (
      <FormattedMessage
        id="label_create_report"
        defaultMessage="Create report"
      />
    ),
    message: (
      <FormattedMessage
        id="message_create_report"
        defaultMessage="Are you sure you want to create a report for {productName}?"
        values={{
          productName: "",
        }}
      />
    ),
    onConfirm: () => {},
  },
  [QUESTION_DIALOG_TYPE.RE_CREATE]: {
    title: (
      <FormattedMessage
        id="label_re_create_report"
        defaultMessage="Re-create report"
      />
    ),
    message: (
      <FormattedMessage
        id="message_re_create_report"
        defaultMessage="Are you sure you want to delete current report and create new?"
      />
    ),
    onConfirm: () => {},
  },
  [QUESTION_DIALOG_TYPE.NONE]: null,
};
