import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import BankIDLogin from "@components/BankidComponent/BankIDLogin";
import { PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";

import { useToast } from "@hooks/useToast";

import { BANK_ID_SCOPES } from "@utils/enum";

const MyProfileBankIdLoginDialog = ({ onClose, onSubmit }) => {
  const { messages } = useIntl();
  const { showErrorToast } = useToast();

  const handleSubmit = values => {
    onSubmit(values);
    onClose();
  };

  const handleError = error => {
    showErrorToast(error ?? messages.error_ssn_already_associated);

    onClose();
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={1}
      maxWidth="450px"
      onHide={onClose}
      m={[3, 3, 3, "auto"]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
      >
        <Icon
          name="bankID"
          fontSize="var(--fs-icon-xl)"
          color="var(--blue-bank-id-logo)"
        />

        <H3 textTransform="none">
          {messages.title_activate_login_with_bank_id}
        </H3>

        <Div width={[1, 1, "200px", "200px"]}>
          <BankIDLogin
            scope={BANK_ID_SCOPES.ENABLE_BANKID}
            buttonLabel={messages.label_activate}
            onSuccess={handleSubmit}
            onError={handleError}
          />
        </Div>

        <PrimaryButtonOutlined
          width={[1, 1, "200px", "200px"]}
          height="40px"
          label={messages.label_cancel}
          onClick={onClose}
        />
      </Div>
    </Dialog>
  );
};

MyProfileBankIdLoginDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default MyProfileBankIdLoginDialog;
