export const REPORT_ELEMENTS_TYPE = {
  PANEL: "panel",
  COMMENT: "comment",
  TEXT: "text",
  PANELDYNAMIC: "paneldynamic",
  CARD: "card",
  TOGGLEABLE: "toggleable",
  HTML: "html",
  RICH_CONTENT_EDITOR: "rich_content_editor",
};
