import React from "react";
import PropTypes from "prop-types";

import CaseReport from "@components/Report/CaseReport/CaseReport";

const ReportPreview = ({ reportConfig, reportData }) => {
  const report = {
    report_config: reportConfig,
    report_data: reportData,
  };

  return <CaseReport hideIcons report={report} />;
};

ReportPreview.propTypes = {
  reportConfig: PropTypes.object,
  reportData: PropTypes.object,
};

export default ReportPreview;
