import React from "react";

import LogoFull from "@assets/logo.png";

import Div from "@components/Div";
import Logo from "@components/Logo";

import Config from "@src/config";

import SideMenu from "./SideMenu";

const GUEST_HEADER_MENU = [];

const GuestHeader = () => {
  const handleRedirect = () => {
    window.location = `${Config.WP_URL}/`;
  };

  const start = (
    <Div py="10px">
      <Logo
        logo={LogoFull}
        width="auto"
        maxHeight="60px"
        onClick={handleRedirect}
      />
    </Div>
  );

  return <SideMenu model={GUEST_HEADER_MENU} start={start} width={1} />;
};

export default GuestHeader;
