import React from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import styled from "styled-components";

import Div from "@components/Div";

import CandidateHeader from "@pages/candidate/CandidateHeader";

import { CANDIDATE } from "@utils/constant";
import ScrollToTop from "@utils/ScrollToTop";

import GuestHeader from "./GuestHeader";

import { ROUTES } from "../utils/constant";

export const StyledDiv = styled(Div)`
  display: flex;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
`;

export const GuestLayout = ({ children }) => {
  const { pathname = "" } = useLocation();
  const isReportPdf = pathname === ROUTES.REPORT_PDF.URL;
  const isCandidatePage = pathname.includes(CANDIDATE);

  return (
    <Div
      display="flex"
      alignItems="center"
      flexDirection="column"
      alignContent="space-between"
      minHeight={"100vh"}
    >
      {!isCandidatePage && !isReportPdf && <GuestHeader />}
      {isCandidatePage && <CandidateHeader />}
      <ScrollToTop>{children}</ScrollToTop>
    </Div>
  );
};

GuestLayout.propTypes = {
  children: PropTypes.element,
};

export default GuestLayout;
