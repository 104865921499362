import React, { useEffect, useRef, useState } from "react";
import SurveyForm from "../../../../components/Survey/SurveyForm";
import ProductDetailConfig from "./ProductDetailConfig.json";
import Dialog from "@components/Dialog";
import { H3 } from "@components/Heading";
import PropTypes from "prop-types";

function ProductEditFieldCard({ title, config, onHide, onSubmit }) {
  const surveyRef = useRef(null);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const initialData = {
      name: config.find(value => value.key === "name")?.value || null,
      price: config.find(value => value.key === "price")?.value || null,
      delivery_time:
        config.find(value => value.key === "delivery_time")?.value || null,
      listing_order:
        config.find(value => value.key === "listing_order")?.value || null,
      default_available:
        config.find(value => value.key === "default_available")?.value ?? null,
      default_selected:
        config.find(value => value.key === "default_selected")?.value ?? null,
    };
    setProductData(initialData);
  }, [config]);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    onSubmit(data);
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, 1, 1]}
      maxWidth={["100%", "100%", "60%", "60%"]}
      onHide={onHide}
      m={[3, 3, 3, "auto"]}
    >
      <H3 textAlign="center">{title}</H3>
      <SurveyForm
        data={productData}
        formConfig={ProductDetailConfig}
        onRefLoaded={handleLoadSurveyRef}
        onComplete={handleSave}
        showCompleteButton={true}
        onClose={onHide}
      />
    </Dialog>
  );
}

ProductEditFieldCard.propTypes = {
  title: PropTypes.string,
  config: PropTypes.array,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ProductEditFieldCard;
