import Axios from "@app/api/axios";
import { COURTS } from "@utils/constant";

const courtsService = () =>
  Axios.get(`${COURTS}`);
export default courtsService;

export const courtsServiceById = (id) => 
  Axios.get(`${COURTS}?filter[id]=${id}`); 

// export const getCourtsServiceById = (id) => {
//   return Axios.get(`${COURTS}?filter[id]=${id}`)
//     .then(({ data: { data } }) => { 
//       const [courtData] = data; 
//       return courtData; 
//     })
// };
