import React, { useEffect, useRef, useState } from "react";
import SurveyForm from "../../../../components/Survey/SurveyForm";
import ProductDescriptionConfig from "./ProductDescriptionConfig.json";
import Dialog from "@components/Dialog";
import { H3 } from "@components/Heading";
import PropTypes from "prop-types";

function DescriptionEditFieldCard({ title, config, onHide, onSubmit }) {
  const surveyRef = useRef(null);
  const [descriptionData, setDescriptionData] = useState([]);

  useEffect(() => {
    const initialData = {
      description:
        config.find(value => value.key === "description")?.value || null,
    };
    setDescriptionData(initialData);
  }, [config]);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    onSubmit(data);
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, 1, 1]}
      maxWidth={["100%", "100%", "60%", "60%"]}
      onHide={onHide}
      m={[3, 3, 3, "auto"]}
    >
      <H3 textAlign="center">{title}</H3>
      <SurveyForm
        data={descriptionData}
        formConfig={ProductDescriptionConfig}
        onRefLoaded={handleLoadSurveyRef}
        onComplete={handleSave}
        showCompleteButton={true}
        onClose={onHide}
      />
    </Dialog>
  );
}

DescriptionEditFieldCard.propTypes = {
  title: PropTypes.string,
  config: PropTypes.array,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DescriptionEditFieldCard;
