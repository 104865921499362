import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Checkbox from "@components/Checkbox";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import {
  Text,
  TextMediumWeight,
  TextUpperCaseSemiBold,
} from "@components/Text";

export const PrintReportDialog = ({ downloadUrl = "", onConfirm, onClose }) => {
  const { messages } = useIntl();

  const [checked, setChecked] = useState(false);

  const pdfDownloadRef = useRef(null);

  const handleChange = e => {
    setChecked(e.checked);
  };

  const handleDownloadReport = async () => {
    onConfirm();
    onClose();
  };

  const renderDownloadAnchor = () => (
    <a
      href={downloadUrl}
      ref={pdfDownloadRef}
      download
      aria-label="downloadPdf"
    />
  );

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 445, 445, 445]}
      onHide={onClose}
      m={[3, 3, 3, "auto"]}
    >
      <Div mt={3} pt={1}>
        <Div display="flex" alignItems="center" flexDirection="column">
          <TextUpperCaseSemiBold mb={2}>
            {messages.label_download_report}
          </TextUpperCaseSemiBold>
          <Text my={3} textAlign="center">
            {messages.download_pdf_content}
          </Text>
        </Div>

        <Div display="flex" mt={2} mb={4} alignItems="flex-start">
          <Div>
            <Checkbox
              fontSize="var(--fs-text)"
              inputId="policy_check"
              checked={checked}
              onChange={handleChange}
            />
          </Div>

          <TextMediumWeight
            htmlFor="policy_check"
            ml={10}
            color="var(--grey-dark)"
            fontWeight="var(--light-weight)"
          >
            {messages.download_report_check}
          </TextMediumWeight>
        </Div>

        <Div
          px={3}
          max-width="250px"
          display={["block", "flex"]}
          alignItems="center"
          justifyContent="space-around"
        >
          <PrimaryButtonOutlined
            mr={2}
            mb={[2, 0]}
            minWidth="85px"
            minHeight="40px"
            width={[1, "auto"]}
            onClick={onClose}
          >
            {messages.label_cancel}
          </PrimaryButtonOutlined>

          <PrimaryButton
            py={2}
            px={4}
            width={[1, "200px"]}
            label={messages.label_download}
            onClick={handleDownloadReport}
            disabled={!checked}
          />
        </Div>
      </Div>

      {renderDownloadAnchor()}
    </Dialog>
  );
};

PrintReportDialog.propTypes = {
  downloadUrl: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default PrintReportDialog;
