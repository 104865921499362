import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import styled from "styled-components";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import { SurveyForm } from "@components/Survey";
import { useIntl } from "react-intl";
import updateRulesService from "@app/services/rules/updateRulesService";
import { getRulesServiceById } from "@app/services/rules/rulesService";
import editRule from "./editRule.json";

const StyledCreateBilling = styled.div`
  width: 1110px;
`;

const EditRuleDialog = ({ editRuleId, handleClose = () => {} }) => {
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const surveyRef = useRef(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const [ruleData, setRuleData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ruleData = await getRulesServiceById(editRuleId);
        const productIdsArray = ruleData.products.map(product => product.id);
        const singleRule = {
          name: ruleData.name,
          type: ruleData.type,
          step: ruleData.step,
          group: ruleData.group,
          products: productIdsArray,
          slug: ruleData.slug,
          properties: JSON.parse(ruleData.properties),
          summary:ruleData.ai_summary,
          detailed_explanation:ruleData.ai_detailed_explanation
        };
        setRuleData(singleRule);
      } catch (error) {
        setRuleData([]);
      }
    };
    fetchData();
  }, [editRuleId]);

  const handleUpdateCase = useCallback(
    async data => {
      try {
        setIsLoading(true);

        const payload = {
          name: data.name,
          type: data.type,
          step: data.step,
          group: data.group,
          products: data.products,
          slug: data.slug,
          properties: JSON.stringify(data.properties),
        };
        await updateRulesService(editRuleId, payload);
        showSuccessToast(messages.label_updated_edited);
        handleClose();
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [messages, showErrorToast, handleClose, showSuccessToast, editRuleId]
  );

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    handleUpdateCase(data);
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog
        title={messages.view_edit_rule_lable}
        onClose={handleClose}
      >
        <StyledCreateBilling>
          <Div bgColor="var(--white)">
            <SurveyForm
              data={ruleData}
              formConfig={editRule}
              onRefLoaded={handleLoadSurveyRef}
              onComplete={handleSave}
              showCompleteButton={true}
              onClose={handleClose}
            />
          </Div>

        </StyledCreateBilling>
      </FullSizeDialog>
    </Div>
  );
};

EditRuleDialog.propTypes = {
  handleClose: PropTypes.func,
  editRuleId: PropTypes.string,
};

export default EditRuleDialog;
