import React from "react";
import { ElementFactory, Question, Serializer, settings } from "survey-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import {
  PropertyGridEditorCollection,
  localization,
} from "survey-creator-core";

import { performApiCall } from "../ReferenceCheck";
import ProgressSpinner from "@components/ProgressSpinner";

const CUSTOM_TYPE = "button";
const BUTTON_EDITOR = "custom_button_editor";

// Create a question model
export class QuestionButtonModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get buttonText() {
    return this.getPropertyValue("buttonText");
  }
  set buttonText(val) {
    this.setPropertyValue("buttonText", val);
  }
  get buttonAction() {
    return this.getPropertyValue("buttonAction");
  }
  set buttonAction(val) {
    this.setPropertyValue("buttonAction", val);
  }
  get apiEndpoint() {
    return this.getPropertyValue("apiEndpoint");
  }
  set apiEndpoint(val) {
    this.setPropertyValue("apiEndpoint", val);
  }
  get assistantId() {
    return this.getPropertyValue("assistantId");
  }
  set assistantId(val) {
    this.setPropertyValue("assistantId", val);
  }
  get targetField() {
    return this.getPropertyValue("targetField");
  }
  set targetField(val) {
    this.setPropertyValue("targetField", val);
  }
  get sourceFields() {
    return this.getPropertyValue("sourceFields");
  }
  set sourceFields(val) {
    this.setPropertyValue("sourceFields", val);
  }
}

// Create a class that renders the Button
export class SurveyQuestionButton extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
    };
    this.handleButtonClick = async () => {
      const action = this.question.buttonAction;
      if (action === "api-call") {
        this.setState({ loading: true });
        try {
          await performApiCall(
            this.question.apiEndpoint,
            this.question.assistantId,
            this.question.targetField,
            JSON.parse(this.question.sourceFields),
            this.question.survey
          );
        } catch (error) {
          this.setState({ error });
        } finally {
          this.setState({ loading: false });
        }
      }
    };
  }
  get question() {
    return this.questionBase;
  }

  renderButton() {
    const isReadOnly = this.question.isReadOnly || this.question.isDesignMode;
    return (
      <button
        type="button"
        disabled={isReadOnly}
        className="btn-custom-event"
        onClick={this.handleButtonClick}
      >
        {this.question.buttonText || "Click Me"}
      </button>
    );
  }

  renderActivityIndicator() {
    return <ProgressSpinner />;
  }

  renderElement() {
    return (
      <div>
        {this.state.loading
          ? this.renderActivityIndicator()
          : this.renderButton()}
      </div>
    );
  }
}

// Register the Button
function applyTranslations() {
  const locale = localization.getLocale("");

  locale.qt[BUTTON_EDITOR] = "Custom Button Editor";
}

function applyIcon() {
  settings.customIcons[`icon-${BUTTON_EDITOR}`] = "icon-action";
}

export function registerButton() {
  // Register the model in `ElementFactory`
  ElementFactory.Instance.registerElement(CUSTOM_TYPE, name => {
    return new QuestionButtonModel(name);
  });

  Serializer.addClass(
    CUSTOM_TYPE,
    [
      { name: "buttonText", default: "Click Me", category: "BA" },
      { name: "buttonAction", type: "string", category: "BA" },
      { name: "apiEndpoint", type: "string", category: "BA" },
      { name: "assistantId", type: "string", category: "BA" },
      { name: "targetField", type: "string", category: "BA" },
      { name: "sourceFields", type: "string", category: "BA" },
    ],
    function () {
      return new QuestionButtonModel("");
    },
    "question"
  );

  ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, props => {
    return React.createElement(SurveyQuestionButton, props);
  });

  PropertyGridEditorCollection.register({
    fit: function (prop) {
      return prop.type === CUSTOM_TYPE;
    },
    getJSON: function () {
      return { type: CUSTOM_TYPE };
    },
  });

  applyTranslations();
  applyIcon();
}
