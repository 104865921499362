import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const generateReportPDF = async (fileName, elementClassName) => {
  const elements = document.querySelectorAll(elementClassName);

  const pdf = new jsPDF("p", "cm", "a4");
  const imgWidth = 21; // A4 width in points
  const pageHeight = 29.7; // A4 height in points
  const margin = 1.27;
  const contentWidth = imgWidth - 2 * margin; // Width of the content area within margins
  const contentHeight = pageHeight - 2 * margin; // Height of the content area within margins
  let position = margin;

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    const canvas = await html2canvas(element, { scale: 1 });
    const imgData = canvas.toDataURL("image/png");
    const imgHeight = (canvas.height * contentWidth) / canvas.width;
    let heightLeft = imgHeight;

    // Add the captured image of the element to the PDF
    pdf.addImage(
      imgData,
      "PNG",
      margin,
      position,
      contentWidth,
      imgHeight,
      null,
      "FAST"
    );
    heightLeft -= contentHeight;

    while (heightLeft >= 0) {
      pdf.addPage();
      position = margin;
      pdf.addImage(
        imgData,
        "PNG",
        margin,
        position,
        contentWidth,
        imgHeight,
        null,
        "FAST"
      );
      heightLeft -= contentHeight;
    }

    if (i < elements.length - 1) {
      pdf.addPage();
      position = margin;
    }
  }

  pdf.save(fileName);
};

export default generateReportPDF;
