import Axios from "@app/api/axios";

export async function performApiCall(
  url,
  assistant_id,
  targetField,
  sourceFields,
  survey
) {
  const deviations = sourceFields.map(
    field_name => survey.getQuestionByName(field_name)?.value || ""
  );
  if (url) {
    const payload = {
      prompt: JSON.stringify(deviations),
      assistant_id: assistant_id,
    };

    // deviations.forEach((deviation, index) => {
    //   const html = generateHTML(deviation, survey);

    //   // eslint-disable-next-line no-console
    //   console.log(survey.data);

    //   survey.data.deviations[index].summary_of_reference = html;
    // });

    // survey.data = { ...survey.data };
    const response = await Axios.post(url, payload);
    const { reference_verification_summary } = response.data.response;
    const summary = survey.getQuestionByName(targetField);
    summary.value = reference_verification_summary;
  }
}

export function generateHTML(values, survey) {
  // eslint-disable-next-line no-console
  console.log(values);

  let html = "";

  // Add source details with titles
  const keys = [
    "source_name",
    "source_title",
    "source_company",
    "candidate_role",
  ];

  keys.forEach(key => {
    const question = survey.getQuestionByName("Frågor");

    // eslint-disable-next-line no-console
    console.log(key, question);
    const title = question?.title || key; // Use corresponding title or fallback to key
    html += `<p><strong>${title}:</strong>${values[key] || ""}</p>`;
  });

  html += "<br />";

  // Add questions and answers
  values.Frågor.forEach(item => {
    html += `<p>${item.question}</p>`;
    if (item.answer) {
      html += `<p>${item.answer}</p>`;
    }
    html += "<br />";
  });

  return html;
}
