import React from "react";
import PropTypes from "prop-types";

import hash from "object-hash";
import styled from "styled-components";

const StyledOrderedList = styled.ol`
  list-style: decimal;
  font-size: var(--fs-text-small);
  font-weight: var(--light-weight);
`;

const OrderedList = ({ content }) => {
  return (
    <StyledOrderedList>
      {content?.map((item, index) => (
        <li key={`${hash(item)}_${index}`}>{item}</li>
      ))}
    </StyledOrderedList>
  );
};

OrderedList.propTypes = {
  content: PropTypes.arrayOf(PropTypes.node),
};

export default OrderedList;
