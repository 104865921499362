import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import Div from "@components/Div";
import Icon from "@components/Icon";
import Link from "@components/Link";
import { PrimaryButton } from "@components/Button";
import StyledInputText from "@components/StyledInputText";

const CouponCodeInputText = styled(StyledInputText)`
  border-radius: 10px !important;
  margin-right: -20px !important;
`;

const ApplyCouponButton = styled(PrimaryButton)`
  z-index: 1;
  border-radius: 50px !important;
`;

const CouponCodeInput = props => {
  const { messages } = useIntl();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isCouponCodeApplied, setIsCouponCodeApplied] = useState(false);
  const [couponCodeText, setCouponCodeText] = useState("");

  const openEditMode = () => {
    setIsEditMode(true);
  };

  const handleCouponCodeTextChange = event => {
    setCouponCodeText(event.target.value.toUpperCase());
  };

  const handleCouponCodeSubmit = () => {
    if (couponCodeText == "") {
      return;
    }

    setIsCouponCodeApplied(true);

    props.onCouponCodeSubmitted(couponCodeText);
  };

  return (
    <Div width="30%" minWidth="180px">
      {!isEditMode && !isCouponCodeApplied && (
        <Link
          justifyContent="flex-end"
          label={messages.coupon_code_label}
          iconPos="left"
          onClick={openEditMode}
        >
          <Icon mx="5px" name="receipt" />
        </Link>
      )}

      {((isEditMode && !isCouponCodeApplied) || props.hasError) && (
        <Div className="p-inputgroup">
          <CouponCodeInputText
            placeholder={messages.coupon_code_title}
            value={couponCodeText.toUpperCase()}
            onChange={handleCouponCodeTextChange}
          />
          <ApplyCouponButton
            justifyContent="center"
            onClick={handleCouponCodeSubmit}
          >
            {messages.label_apply}
          </ApplyCouponButton>
        </Div>
      )}

      {isCouponCodeApplied && !props.hasError && (
        <Link
          justifyContent="flex-end"
          label={messages.coupon_code_applied_label}
          iconPos="left"
          onClick={openEditMode}
        >
          <Icon mx="8px" name="mark" />
        </Link>
      )}
    </Div>
  );
};

CouponCodeInput.propTypes = {
  onCouponCodeSubmitted: PropTypes.func,
  hasError: PropTypes.bool,
};

export default CouponCodeInput;
