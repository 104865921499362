import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import styled from "styled-components";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import { SurveyForm } from "@components/Survey";
import { useIntl } from "react-intl";
import createRulesService from "../../../app/services/rules/createRulesService";
import rule from "./rule.json";

const StyledCreateBilling = styled.div`
  width: 1110px;
`;

const CreateRuleDialog = ({ handleClose = () => {} }) => {
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const surveyRef = useRef(null);
  const { showErrorToast, showSuccessToast } = useToast();

const handleUpdateCase = useCallback(
    async data => {
      try {
        setIsLoading(true);

        const payload = {
          name: data.name,
          type: data.type,
          step: data.step,
          group: data.group,
          products: data.products,
          slug: data.slug,
          properties: JSON.stringify(data.properties),
          };
          await createRulesService(payload);
          showSuccessToast(messages.label_rule_added);
          handleClose();
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [ messages, showErrorToast, handleClose, showSuccessToast]
  );

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    handleUpdateCase(data);
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog title={messages.label_add_rule} onClose={handleClose}>
        <StyledCreateBilling>
          <Div bgColor="var(--white)">
            <SurveyForm
              // data={reportData}
              formConfig={rule}
              onRefLoaded={handleLoadSurveyRef}
              onComplete={handleSave}
              showCompleteButton={true}
              onClose={handleClose}
            />
          </Div>
        </StyledCreateBilling>
      </FullSizeDialog>
    </Div>
  );
};

CreateRuleDialog.propTypes = {
  handleClose: PropTypes.func,
};

export default CreateRuleDialog;
