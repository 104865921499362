import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import { Error } from "@components/Heading";
import EditableFieldsCard from "@components/EditableFieldsCard";

import { getBillingAccountsListService } from "@app/services/billingAccounts/getBillingAccountsListService";
import ordersBillingAccountService from "@app/services/orders/ordersBillingAccountService";

import { FORM_INPUT_TYPE, INVOICE_STATUS } from "@utils/enum";
import { enumValueToTranslationKey, isAdminUser } from "@utils/utils";

const LABELED_STATUSES = {
  [INVOICE_STATUS.INVOICED]: {
    value: INVOICE_STATUS.INVOICED,
    label: (
      <FormattedMessage
        id={`label_${enumValueToTranslationKey(INVOICE_STATUS.INVOICED)}`}
      />
    ),
  },
  [INVOICE_STATUS.INVOICING]: {
    value: INVOICE_STATUS.INVOICING,
    label: (
      <FormattedMessage
        id={`label_${enumValueToTranslationKey(INVOICE_STATUS.INVOICING)}`}
      />
    ),
  },
  [INVOICE_STATUS.NOT_INVOICED]: {
    value: INVOICE_STATUS.NOT_INVOICED,
    label: (
      <FormattedMessage
        id={`label_${enumValueToTranslationKey(INVOICE_STATUS.NOT_INVOICED)}`}
      />
    ),
  },
  [INVOICE_STATUS.CANCELED]: {
    value: INVOICE_STATUS.CANCELED,
    label: (
      <FormattedMessage
        id={`label_${enumValueToTranslationKey(INVOICE_STATUS.CANCELED)}`}
      />
    ),
  },
};

const POSSIBLE_STATUSES = {
  [INVOICE_STATUS.INVOICED]: [
    LABELED_STATUSES[INVOICE_STATUS.INVOICED],
    LABELED_STATUSES[INVOICE_STATUS.NOT_INVOICED],
    LABELED_STATUSES[INVOICE_STATUS.CANCELED],
  ],

  [INVOICE_STATUS.INVOICING]: [LABELED_STATUSES[INVOICE_STATUS.INVOICING]],

  [INVOICE_STATUS.CANCELED]: [
    LABELED_STATUSES[INVOICE_STATUS.CANCELED],
    LABELED_STATUSES[INVOICE_STATUS.NOT_INVOICED],
  ],

  [INVOICE_STATUS.NOT_INVOICED]: [
    LABELED_STATUSES[INVOICE_STATUS.NOT_INVOICED],
    LABELED_STATUSES[INVOICE_STATUS.CANCELED],
  ],
};

const getConfig = ({
  billingAccount,
  billing_account_id,
  reference_person,
  invoice_text,
  address_1,
  zip_code,
  address_2,
  city,
  invoice_status,
  invoice_id,
  invoice_date,
  filteredBillingAccounts,
  handleFilterBillingAccounts,
}) => {
  return [
    {
      key: "billing_account",
      translationKey: "label_billing_account",
      value: billingAccount,
      type: FORM_INPUT_TYPE.AUTOCOMPLETE,
      isReadOnlyField: invoice_status !== INVOICE_STATUS.NOT_INVOICED,
      selectorField: "name",
      options: filteredBillingAccounts,
      completeMethod: handleFilterBillingAccounts,
      autocompleteItemOptions: [
        {
          key: "your_reference",
          translationKey: "label_your_reference_person",
        },
        {
          key: "invoice_remark",
          translationKey: "heading_invoice_reference",
        },
        {
          key: "address_1",
          translationKey: "label_address",
        },
      ],
    },
    {
      key: "billing_account_id",
      translationKey: "label_billing_account_id",
      value: billing_account_id,
      type: FORM_INPUT_TYPE.TEXT,
      isReadOnlyField: true,
    },
    {
      key: "reference_person",
      translationKey: "header_reference_person",
      value: reference_person,
      type: FORM_INPUT_TYPE.TEXT,
      isReadOnlyField: true,
    },
    {
      key: "invoice_text",
      translationKey: "heading_invoice_reference",
      value: invoice_text,
      type: FORM_INPUT_TYPE.TEXT,
      isReadOnlyField: true,
    },
    {
      key: "address_1",
      translationKey: "label_invoice_address_line1",
      value: address_1,
      type: FORM_INPUT_TYPE.TEXT,
      isReadOnlyField: true,
    },
    {
      key: "zip_code",
      translationKey: "label_postal_code",
      value: zip_code,
      type: FORM_INPUT_TYPE.TEXT,
      isReadOnlyField: true,
    },
    {
      key: "address_2",
      translationKey: "label_invoice_address_line2",
      value: address_2,
      type: FORM_INPUT_TYPE.TEXT,
      isReadOnlyField: true,
    },
    {
      key: "city",
      translationKey: "label_city",
      value: city,
      type: FORM_INPUT_TYPE.TEXT,
      isReadOnlyField: true,
    },
    {
      key: "invoice_status",
      translationKey: "label_invoice_status",
      value: invoice_status,
      type: FORM_INPUT_TYPE.DROPDOWN,
      isAdminField: true,
      options: POSSIBLE_STATUSES[invoice_status],
      selectorField: "label",
      placeholder: <FormattedMessage id="label_choose" />,
    },
    {
      key: "invoice_id",
      translationKey: "label_invoice_id",
      value: invoice_id,
      type: FORM_INPUT_TYPE.TEXT,
      isAdminField: true,
      isReadOnlyField: true,
    },
    {
      key: "invoice_date",
      translationKey: "label_invoice_date",
      value: invoice_date,
      type: FORM_INPUT_TYPE.CALENDAR,
      isAdminField: true,
      isReadOnlyField: true,
    },
  ];
};

const OrderBillingCard = ({ orderSummaryProps, onUpdateOrderSummaryProps }) => {
  const { messages } = useIntl();

  const [billingAccounts, setBillingAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");

  const { billing_account_id, invoice_status, invoice_id, invoice_date } =
    orderSummaryProps;

  const isEditAllowed =
    isAdminUser() || invoice_status === INVOICE_STATUS.NOT_INVOICED;

  useEffect(() => {
    const fetchBillingAccountsForCustomer = async () => {
      try {
        const { data: { data: billingAccountsList = [] } = {} } =
          await getBillingAccountsListService(
            "per_page=2000&fields=id,name,your_reference,invoice_remark,address_1,zip_code,address_2,city,active&filter[active]=1"
          );

        setBillingAccounts(billingAccountsList);
      } catch (error) {
        setError(messages.exception_error_message);
      }
    };

    fetchBillingAccountsForCustomer();
  }, [billing_account_id, messages.exception_error_message]);

  const usedBillingAccount =
    billingAccounts.find(account => account.id === billing_account_id) || {};

  const filteredBillingAccounts = searchQuery
    ? billingAccounts.filter(
        ({ name, your_reference, invoice_remark, address_1 }) => {
          const itemValuesString = Object.values({
            name,
            your_reference,
            invoice_remark,
            address_1,
          })
            .filter(value => value != null)
            .reduce(
              (accumulator, value) => accumulator + String(value) + "|",
              ""
            );

          return itemValuesString
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      )
    : billingAccounts;

  const handleFilterBillingAccounts = event => {
    const { query } = event;

    if (!query.trim().length) {
      setSearchQuery("|");
      return;
    }

    setSearchQuery(query.trim());
  };

  const BillingInfoConfig = getConfig({
    billingAccount: usedBillingAccount || 0,
    billing_account_id,
    reference_person: usedBillingAccount?.your_reference,
    invoice_text: usedBillingAccount?.invoice_remark,
    address_1: usedBillingAccount?.address_1,
    zip_code: usedBillingAccount?.zip_code,
    address_2: usedBillingAccount?.address_2,
    city: usedBillingAccount?.city,
    invoice_status,
    invoice_id,
    invoice_date,
    filteredBillingAccounts,
    handleFilterBillingAccounts,
  });

  const handleSubmit = async values => {
    try {
      const { billing_account: { id } = {}, invoice_status } = values;
      const { order_id } = orderSummaryProps;

      const payload = {
        billing_account_id: id,
        invoice_status,
      };

      const { data: updatedOrder = {} } = await ordersBillingAccountService(
        order_id,
        payload
      );

      updatedOrder.watchList = null;

      onUpdateOrderSummaryProps(updatedOrder);
    } catch (error) {
      setError(messages.exception_error_message);
    }
  };

  return (
    <Div mt={-3} maxWidth="1110px">
      <EditableFieldsCard
        fullWidthInputs
        title={messages.label_billing_information}
        config={BillingInfoConfig}
        isEditPermission={isEditAllowed}
        onSubmit={handleSubmit}
      />

      {error && <Error>{error}</Error>}
    </Div>
  );
};

OrderBillingCard.propTypes = {
  orderSummaryProps: PropTypes.object,
  onUpdateOrderSummaryProps: PropTypes.func,
};

export default OrderBillingCard;
