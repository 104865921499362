import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import { SurveyForm } from "@components/Survey";
import { useIntl } from "react-intl";
import updatePermissionsService from "@app/services/permissions/updatePermissionsService";
import permissionsConfig from "./permissionsConfig.json";
import { permissionsServiceById } from "@app/services/permissions/permissionsService";
const StyledCreateBilling = styled.div`
  width: 1110px;
`;

const EditPermissionDialog = ({ editPermissionId, handleClose = () => {} }) => {
  const { messages } = useIntl();
  const { showErrorToast, showSuccessToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [permissionData, setPermissionData] = useState(null);

  const surveyRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await permissionsServiceById(editPermissionId);
        const permissionData = data.data[0];
        setPermissionData(permissionData);
      } catch (error) {
        setPermissionData(null);
      }
    };

    fetchData();
  }, [editPermissionId]);

  const handleUpdateCase = useCallback(
    async data => {
      try {
        setIsLoading(true);
        await updatePermissionsService(editPermissionId, data);
        showSuccessToast(messages.label_rule_updated); // Updated message
        handleClose();
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [editPermissionId, messages, showErrorToast, handleClose, showSuccessToast]
  );
  const handleCompleteSurvey = useCallback(
    survey => {
      handleUpdateCase(survey);
    },
    [handleUpdateCase]
  );

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};
    handleUpdateCase(data);
  };
  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog
        title={messages.label_view_edit_permission}
        onClose={handleClose}
      >
        <StyledCreateBilling>
          <Div bgColor="var(--white)">
            <SurveyForm
              data={permissionData}
              formConfig={permissionsConfig}
              onComplete={handleCompleteSurvey}
              onRefLoaded={handleLoadSurveyRef}
            />
          </Div>

          {!isLoading && (
            <Div
              display="flex"
              flexDirection={["column", "column", "row", "row"]}
              gridGap={3}
              ml={4}
            >
              <PrimaryButton label={messages.label_save} onClick={handleSave} />
              <PrimaryButtonOutlined
                backgroundColor="transparent !important"
                minWidth="150px"
                label={messages.label_back}
                onClick={handleClose}
              />
            </Div>
          )}
        </StyledCreateBilling>
      </FullSizeDialog>
    </Div>
  );
};

EditPermissionDialog.propTypes = {
  handleClose: PropTypes.func,
  editPermissionId: PropTypes.string.isRequired,
};

export default EditPermissionDialog;
