import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, space, flexbox, layout } from "styled-system";
import { Menubar as PRStyledMenubar } from "primereact/menubar";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";
import useHistory from "@utils/useHistory";
import { Menu as ModelMenubar } from "primereact/menu";
import Icon from "@components/Icon";
import { useIntl } from "react-intl";
import { TextMediumWeight } from "@components/Text";
import Div from "@components/Div";

const mobileBreakPoint = Config.mobileBreakPoint;
const MenuBar = styled(PRStyledMenubar)`
  position: sticky;
  max-height: 135px;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  border: none;
  .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    box-shadow: none;
  }
  ${compose(space, flexbox, layout)};
  .p-menubar-root-list {
    width: -webkit-fill-available;
    .p-menuitem {
      .p-menuitem-link {
        padding: 0px 15px;
      }
    }
  }
  .p-menubar-button {
    display: none;
  }
  .p-menubar-end {
    height: 136px;
    display: inline-flex;
    margin-left: 22px;
    & > div {
      display: flex;
      flex-direction: row;
    }
  }
  &.p-menubar.p-component {
    padding: 0px 48px 0px;
    background: var(--site-background-color);
    .p-menubar-root-list {
      padding-left: 20px;
      padding-top: 70px;
      .p-menuitem {
        &:last-child {
          .p-menuitem-link {
            &:hover {
              background: var(--transparent);
            }
            .p-menuitem-text {
              &:hover {
                border-bottom: 5px solid transparent;
              }
            }
          }
        }
        .p-menuitem-link {
          background: transparent;
          transition: none !important;
          &:hover {
            background: var(--white);
          }
          .p-menuitem-text {
            padding-bottom: 30px;
            border-bottom: 5px solid transparent;
            color: var(--grey-dark) !important;
            font-size: var(--fs-text-m);
            line-height: 22px;
            font-weight: var(--medium-weight) !important;
            white-space: nowrap;
            &:hover {
              border-bottom: 5px solid var(--blue-medium);
              color: var(--blue-medium) !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    min-height: 83px;
    .p-menubar-button,
    .p-menubar-end {
      display: none !important;
    }
    .p-menubar-start {
      width: 100%;
      display: flex;
      align-content: space-between;
      justify-content: space-between;
      align-items: center;
    }
    &.p-menubar.p-component {
      padding: 16px 23px 22px;
      .p-menubar-root-list {
        display: none !important;
      }
    }
  }
`;
const Menu = styled(ModelMenubar)`
  ${compose(space, flexbox, layout)};
  top: 134px !important;
  position: fixed;
  .p-menuitem-link {
    &:focus {
      box-shadow: none;
    }
  }
  .p-menuitem-text {
    color: var(--grey-dark) !important;
    font-size: var(--fs-text-m);
    line-height: 22px;
    font-weight: var(--medium-weight) !important;
  }
`;
const SideMenu = props => {
  const { model, start, end } = props;
  const history = useHistory();
  const { messages } = useIntl();

  const menu = useRef(false);

  const handleRedirectToWPpage = url => {
    window.location = url;
  };

  let items = model.map(item => {
    const {
      redirectTo = "",
      isWpPage = "",
      value = "",
      preventRedirect = false,
      isDropDown = false,
      dropDownOptions = [],
    } = item;

    const options = dropDownOptions.map(o => ({
      ...o,
      command: handleRedirectToWPpage.bind(this, o.redirectTo),
    }));

    if (isDropDown) {
      const label = (
        <>
          <Div display={["none", "none", "none", "flex"]}>
            <Menu model={options} popup ref={menu} />
            <TextMediumWeight onClick={event => menu.current.toggle(event)}>
              {messages.om_oss}
              <Icon
                mt={1}
                height={5}
                color="var(--turquoise)"
                name="chevrondown"
                verticalAlign="middle"
              />
            </TextMediumWeight>
          </Div>
          <Div display={["flex", "flex", "flex", "none"]}>
            <TextMediumWeight>
              {messages.om_oss}
              <Icon
                mt={1}
                height={5}
                color="var(--turquoise)"
                name="chevrondown"
                verticalAlign="middle"
              />
            </TextMediumWeight>
          </Div>
        </>
      );

      item.label = label;
    }
    if (isWpPage) {
      item.url = redirectTo;
    } else if (!isDropDown) {
      item.command = () => {
        if (!preventRedirect) {
          history.push(value);
        }
      };
    }
    return item;
  });

  return <MenuBar model={items} start={start} end={end} {...props} />;
};
SideMenu.propTypes = {
  model: PropTypes.array,
  start: PropTypes.object,
  end: PropTypes.object,
};
export default SideMenu;
