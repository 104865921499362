import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import { Text } from "@components/Text";

import useAuthorization from "@hooks/useAuthorization";

import { ROUTES } from "@utils/constant";

const BANK_ID_LOGIN_REMINDER_RELEASE_DATE = "2024-11-18T09:00:00.000000Z";

const BankIDLoginReminderDialog = () => {
  const { isAdminUser } = useAuthorization();
  const history = useHistory();
  const { messages } = useIntl();
  const { ssn, last_login_at } = useSelector(
    state => state.authReducer.userInfo
  );

  const [isBankIdReminderDialogVisible, setIsBankIdReminderDialogVisible] =
    useState(false);

  useEffect(() => {
    const isBankIdLoginEnabled = ssn !== null;
    const lastLoginDate = new Date(last_login_at);
    const thresholdDate = new Date(BANK_ID_LOGIN_REMINDER_RELEASE_DATE);
    const needToShowBankIdLoginReminder = lastLoginDate < thresholdDate;
    const isVisible = !isBankIdLoginEnabled && needToShowBankIdLoginReminder;

    setIsBankIdReminderDialogVisible(isVisible);
  }, [isAdminUser, last_login_at, ssn]);

  const handleCloseBankIdReminderDialog = () => {
    setIsBankIdReminderDialogVisible(false);
  };

  const handleRedirectToMyProfile = () => {
    handleCloseBankIdReminderDialog();

    const targetUrl = isAdminUser
      ? ROUTES.ADMIN_MY_PROFILE.URL
      : ROUTES.USER_PROFILE.URL;

    history.push(targetUrl);
  };

  return (
    <Dialog
      visible={isBankIdReminderDialogVisible}
      draggable={false}
      width={1}
      maxWidth="500px"
      onHide={handleCloseBankIdReminderDialog}
      m={[3, 3, 3, "auto"]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
      >
        <Icon name="bankID" fontSize="var(--fs-icon-xl)" />
        <H3>{messages.title_bankid_login}</H3>
        <Text textAlign="center">{messages.message_bank_id_available}</Text>
        <Div
          width={1}
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          alignItems="center"
          justifyContent="center"
          gridGap={[3, 3, 4, 4]}
        >
          <PrimaryButtonOutlined
            width={[1, 1, "fit-content", "fit-content"]}
            minWidth="200px"
            height="40px"
            label={messages.label_maybe_later}
            onClick={handleCloseBankIdReminderDialog}
          />

          <PrimaryButton
            type="submit"
            width={[1, 1, "fit-content", "fit-content"]}
            minWidth="200px"
            height="40px"
            label={messages.label_activate}
            onClick={handleRedirectToMyProfile}
          />
        </Div>
      </Div>
    </Dialog>
  );
};

export default BankIDLoginReminderDialog;
