import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { deleteBillingAccountService } from "@app/services/billingAccounts/deleteBillingAccountService";
import { getBillingAccountService } from "@app/services/billingAccounts/getBillingAccountService";
import patchAccountStatus from "@app/services/billingAccounts/patchAccountStatus";
import { updateBillingAccountService } from "@app/services/billingAccounts/updateBillingAccountService";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions, CustomerPermissions } from "@src/enum/Permissions";

import countries from "@utils/countries.json";
import { INVOICE_TYPE, FORM_INPUT_TYPE } from "@utils/enum";
import { enumValueToTranslationKey, getCountryDetails } from "@utils/utils";
import { useHistory } from "react-router-dom";

import {
  BasicInformationSchema,
  InvoiceReferenceSchema,
  PaymentSchema,
} from "../UpdateBillingInfoSchema";

const getCountryName = (code, originalName) => {
  return (
    getCountryDetails(code, "code", "name") ||
    getCountryDetails(originalName, "original_name", "name")
  );
};

const getCountryCode = name => {
  return (
    getCountryDetails(name, "name", "code") ||
    getCountryDetails(name, "original_name", "code")
  );
};

const getConfig = billingAccountInfo => ({
  billingInfo: [
    {
      key: "name",
      translationKey: "header_company_name",
      value: billingAccountInfo?.name,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "registration_nr",
      translationKey: "label_organisation_number",
      value: billingAccountInfo?.registration_nr,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "address_1",
      translationKey: "label_invoice_address_line1",
      value:
        billingAccountInfo?.address_1 !== "Not Set"
          ? billingAccountInfo?.address_1
          : "",
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "address_2",
      translationKey: "label_invoice_address_line2",
      value: billingAccountInfo?.address_2,
      type: FORM_INPUT_TYPE.TEXT,
    },
    {
      key: "zip_code",
      translationKey: "label_postal_code",
      value: billingAccountInfo?.zip_code,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "city",
      translationKey: "label_city",
      value: billingAccountInfo?.city,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "country_name",
      translationKey: "label_country",
      value: getCountryName(
        billingAccountInfo?.country_code,
        billingAccountInfo?.country_name
      ),
      placeholderKey: "placeholder_choose_country",
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      options: countries.map(countryItem => countryItem.name),
    },
    { type: FORM_INPUT_TYPE.FILLER },
  ],
  invoiceReference: [
    {
      key: "your_reference",
      translationKey: "label_your_reference_person",
      value: billingAccountInfo?.your_reference,
      type: FORM_INPUT_TYPE.TEXT,
    },
    { type: FORM_INPUT_TYPE.FILLER },
    {
      key: "invoice_remark",
      translationKey: "heading_invoice_reference",
      value: billingAccountInfo?.invoice_remark,
      type: FORM_INPUT_TYPE.TEXT,
    },
    { type: FORM_INPUT_TYPE.FILLER },
  ],
  payment: [
    {
      key: "invoice_type",
      translationKey: "label_invoice_type",
      value: billingAccountInfo?.invoice_type,
      placeholderKey: "placeholder_choose_payment_option",
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      options: [
        {
          value: INVOICE_TYPE.E_INVOICE,
          label: (
            <FormattedMessage
              id={`label_${enumValueToTranslationKey(INVOICE_TYPE.E_INVOICE)}`}
            />
          ),
        },
        {
          value: INVOICE_TYPE.EMAIL_INVOICE,
          label: (
            <FormattedMessage
              id={`label_${enumValueToTranslationKey(
                INVOICE_TYPE.EMAIL_INVOICE
              )}`}
            />
          ),
        },
      ],
      selectorField: "label",
      optionFields: {
        "E-invoice": [
          { type: FORM_INPUT_TYPE.FILLER },
          {
            key: "edi_identification",
            translationKey: "label_edi_identification",
            value: billingAccountInfo?.edi_identification,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
          {
            key: "peppol_address",
            translationKey: "label_electronic_address_in_peppol",
            value: billingAccountInfo?.peppol_address,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
          {
            key: "gln",
            translationKey: "label_gln_number",
            value: billingAccountInfo?.gln,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
          {
            key: "van_operator",
            translationKey: "label_van_operator",
            value: billingAccountInfo?.van_operator,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
        ],
        "Email invoice": [
          { type: FORM_INPUT_TYPE.FILLER },
          {
            key: "email_invoice",
            translationKey: "header_billing_email",
            value: billingAccountInfo?.email_invoice,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
        ],
      },
    },
    { type: FORM_INPUT_TYPE.FILLER },
    {
      key: "fortnox_customer_id",
      translationKey: "label_fortnox_id",
      value: billingAccountInfo?.fortnox_customer_id,
      type: FORM_INPUT_TYPE.TEXT,
      isAdminField: true,
    },
  ],
});

const BillingDetails = ({
  billingAccountId,
  customerId,
  onBillingInfoLoaded,
}) => {
  const { isAdmin, hasAnyPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [billingAccountInfo, setBillingAccountInfo] = useState(null);

  const hasWriteBillingPermission = hasAnyPermissions([
    AdminPermissions.AdminWriteBilling,
  ]);
  const hasWriteCompanyInformationPermission = hasAnyPermissions([
    CustomerPermissions.CustomerWriteCompanyInformation,
  ]);

  const shouldShowDeleteButton = isAdmin && hasWriteBillingPermission;
  const shouldShowActivationToggleButton = isAdmin
    ? hasWriteBillingPermission
    : hasWriteCompanyInformationPermission;

  const { billingInfo, invoiceReference, payment } =
    getConfig(billingAccountInfo);

  useEffect(() => {
    const fetchBillingAccountInfo = async () => {
      try {
        setIsLoading(true);

        const { data: { data: billingAccountData = {} } = {} } =
          await getBillingAccountService(billingAccountId);

        const formattedBillingAccountInfo = {
          ...billingAccountData,
          registration_nr: billingAccountData.organisation_number,
        };

        setBillingAccountInfo(formattedBillingAccountInfo);

        onBillingInfoLoaded(formattedBillingAccountInfo);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingAccountInfo();
  }, [
    billingAccountId,
    messages.exception_error_message,
    onBillingInfoLoaded,
    showErrorToast,
  ]);

  const handleToggleActiveStatus = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data: { data: updatedBillingAccountData = {} } = {} } =
        await patchAccountStatus({
          billing_account_id : billingAccountId,
          active: Number(!billingAccountInfo?.active),
        });

      setBillingAccountInfo({
        ...updatedBillingAccountData,
        registration_nr: updatedBillingAccountData.organisation_number,
      });

      showSuccessToast(
        updatedBillingAccountData.active
          ? messages.label_billing_account_activated_successfully
          : messages.label_billing_account_deactivated_successfully
      );
      history.goBack();
    } catch (e) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  }, [
    billingAccountInfo?.active,
    billingAccountId,
    messages,
    showSuccessToast,
    showErrorToast,
    history,
  ]);

  const handleSubmit = useCallback(
    async ({ registration_nr, ...values }) => {
      try {
        setIsLoading(true);
        const payload = {
          ...billingAccountInfo,
          ...values,
          id: billingAccountId,
          customer_id: customerId,
        };

        if (registration_nr) {
          payload.organisation_number = registration_nr;
        }

        if (payload.fortnox_customer_id === null) {
          delete payload.fortnox_customer_id;
        }

        if (payload.country_name) {
          const countryCode = getCountryCode(payload.country_name);

          payload.country_code = countryCode;
        }

        const { data: { data: updatedBillingAccountData = {} } = {} } =
          await updateBillingAccountService(payload);

        setBillingAccountInfo({
          ...updatedBillingAccountData,
          registration_nr: updatedBillingAccountData.organisation_number,
        });

        showSuccessToast(messages.label_billing_account_updated_successfully);
      } catch (error) {
        const { response: { data: { errors } = {} } = {} } = error;

        const errorString = Object.keys(errors).reduce(
          (accumulator, currentKey) =>
            accumulator + messages[`validation_enter_${currentKey}`] + ". ",
          ""
        );

        const message = errorString ?? messages.exception_error_message;

        showErrorToast(message);
      } finally {
        setIsLoading(false);
      }
    },
    [
      billingAccountId,
      billingAccountInfo,
      customerId,
      messages,
      showErrorToast,
      showSuccessToast,
    ]
  );

  const handleDeleteBillingAccount = useCallback(async () => {
    try {
      setIsLoading(true);

      await deleteBillingAccountService(billingAccountId);

      setIsLoading(false);
      showSuccessToast(messages.text_deleted_successful);
      history.goBack();
    } catch (error) {
      showErrorToast(messages.exception_error_message);
      setIsLoading(false);
    }
  }, [
    billingAccountId,
    messages.exception_error_message,
    messages.text_deleted_successful,
    showErrorToast,
    showSuccessToast,
    history
  ]);

  return isLoading ? (
    <ProgressSpinner />
  ) : (
    <Div display="flex" flexDirection="column" >
      <EditableFieldsCard
        title={messages.heading_basic_information}
        config={billingInfo}
        onSubmit={handleSubmit}
        validationSchema={BasicInformationSchema}
        isEditPermission={shouldShowActivationToggleButton}
      />

      <EditableFieldsCard
        title={messages.heading_invoice_reference}
        config={invoiceReference}
        onSubmit={handleSubmit}
        validationSchema={InvoiceReferenceSchema}
        isEditPermission={shouldShowActivationToggleButton}
      />

      <EditableFieldsCard
        title={messages.label_billing_info_payment}
        config={payment}
        onSubmit={handleSubmit}
        validationSchema={PaymentSchema}
        isEditPermission={shouldShowActivationToggleButton}
      />

      <Div
        mt={4}
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        gridGap={[3, 3, 4, 4]}
      >
        {shouldShowActivationToggleButton && (
          <PrimaryButton
            label={
              billingAccountInfo?.active
                ? messages.label_deactivate_billing_account
                : messages.label_activate_billing_account
            }
            onClick={handleToggleActiveStatus}
          />
        )}

        {shouldShowDeleteButton && (
          <PrimaryButtonOutlined
            width={[1, 1, "fit-content", "fit-content"]}
            label={messages.label_delete_billing_account}
            onClick={handleDeleteBillingAccount}
          />
        )}
      </Div>
    </Div>
  );
};
BillingDetails.propTypes = {
  billingAccountId: PropTypes.string,
  customerId: PropTypes.string,
  onBillingInfoLoaded: PropTypes.func,
};
export default BillingDetails;
