import styled from "styled-components";
import { Card as PRCard } from "primereact/card";
import {
  compose,
  space,
  layout,
  flexbox,
  color,
  typography,
} from "styled-system";

const GDPRInformationBox = styled(PRCard)`
  ${compose(layout, space, flexbox, color, typography)};
  border: 1px dashed var(--turquoise);
  border-radius: 10px;
  background: transparent;
  margin-top: 32px;

  .p-card-body {
    height: auto;
    padding: 0px;
    .p-card-content {
      padding: 16px 16px;
      height: auto;
    }
  }
`;

export default GDPRInformationBox;
