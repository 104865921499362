import React from "react";
import { useIntl } from "react-intl";

import styled from "styled-components";

import Div from "@components/Div";
import { default as PRIcon } from "@components/Icon";
import GDPRInformationBox from "@components/privacyPolicies/GDPRInformationBox";
import { Text } from "@components/Text";
import Link from "@components/Link";

const Icon = styled(PRIcon)`
  color: var(--blue-dark);
  background: var(--grey-lightest);
  padding: 6px;
  border-radius: 50%;
`;

const GDPRInformationMessage = () => {
  const { messages } = useIntl();

  return (
    <GDPRInformationBox>
      <Div display="flex" alignItems="center">
        <Div mr={[3, 2]}>
          <Icon name="sign"></Icon>
        </Div>

        <Text pl={[0, "10px"]}>
          {messages.privacy_policies_text}{" "}
          <Link divDisplay="inline" label={messages.privacy_policies_link_text} href="https://stage.bakgrundsanalys.se/personuppgiftspolicy/" />
         
        </Text>
      </Div>
    </GDPRInformationBox>
  );
};

export default GDPRInformationMessage;
