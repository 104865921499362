import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButtonIconOutlined } from "@components/Button";
import Div from "@components/Div";
import ProgressSpinner from "@components/ProgressSpinner";

import { useBankIDLogin } from "@hooks/useBankIDLogin";

import BankIDModal from "@pages/shared/CreateOrderWizard/orderSummary/BankIDModal";

import { FAILED_STATUS, PENDING_STATUS } from "@utils/constant";
import { isMobile } from "@utils/utils";

import BankIdInitMobileDialog from "./BankIdInitMobileDialog";

const isValidSSN = (ssn, bankIdSSN) => {
  if (!ssn) {
    return true;
  }

  return ssn.toString().replace("-", "") === bankIdSSN.replace("-", "");
};

const BankIDLogin = ({
  ssn,
  scope,
  candidateId,
  userNonVisibleData,
  buttonLabel,
  onSuccess,
  onError,
}) => {
  const { messages } = useIntl();

  const [isQRCodeModalVisible, setIsQRCodeModalVisible] = useState(false);
  const [isMobileInitDialogVisible, setIsMobileInitDialogVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const {
    qrCodeData,
    bankIdFeedback,
    onUseQrCode,
    onUseBankIdApp,
    onCancelBankId,
  } = useBankIDLogin({ scope, candidateId, userNonVisibleData, onError });

  useEffect(() => {
    const checkBankIdStatus = () => {
      if (!bankIdFeedback.status || bankIdFeedback.status === PENDING_STATUS) {
        return;
      }

      if (bankIdFeedback.status === FAILED_STATUS) {
        const errorMessage =
          messages[bankIdFeedback?.message_term] ?? bankIdFeedback?.error;

        if (errorMessage) {
          onError?.(errorMessage);
        }

        setIsQRCodeModalVisible(false);

        return;
      }

      if (!isValidSSN(ssn, bankIdFeedback.ssn)) {
        setIsQRCodeModalVisible(false);
        onError(messages.bankid_ssn_mismatch);

        return;
      }

      onSuccess(bankIdFeedback);
    };

    checkBankIdStatus();

    const handleVisibilityChange = () => {
      checkBankIdStatus();
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [bankIdFeedback, messages, onError, onSuccess, ssn]);

  const handleUseBankIdApp = () => {
    setLoading(true);

    onUseBankIdApp();

    setLoading(false);
  };

  const handleUseQRCode = () => {
    onUseQrCode();
    setIsQRCodeModalVisible(true);
  };

  const handleCloseModal = () => {
    onCancelBankId();
  };

  const handleTryAgain = () => {
    onCancelBankId();
    onUseQrCode();
  };

  const handleOpenMobileInitDialog = () => {
    setIsMobileInitDialogVisible(true);
  };

  const handleCloseMobileInitDialog = () => {
    setIsMobileInitDialogVisible(false);
  };

  return loading ? (
    <ProgressSpinner />
  ) : (
    <Div width={1}>
      {isQRCodeModalVisible && qrCodeData && (
        <BankIDModal
          hideModal={handleCloseModal}
          qrCodeData={qrCodeData}
          bankIDFeedback={bankIdFeedback}
          onTryAgain={handleTryAgain}
          onUseApp={handleUseBankIdApp}
        />
      )}

      {isMobileInitDialogVisible && (
        <BankIdInitMobileDialog
          handleUseAnotherDevice={handleUseQRCode}
          handleUseApp={handleUseBankIdApp}
          onClose={handleCloseMobileInitDialog}
        />
      )}

      <PrimaryButtonIconOutlined
        width={1}
        minHeight="40px"
        onClick={isMobile ? handleOpenMobileInitDialog : handleUseQRCode}
        type="button"
        icon="icon-bankID"
        label={buttonLabel ?? messages.label_initiate_bank_id}
      />
    </Div>
  );
};

BankIDLogin.propTypes = {
  ssn: PropTypes.string,
  scope: PropTypes.string,
  candidateId: PropTypes.string,
  userNonVisibleData: PropTypes.string,
  buttonLabel: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default BankIDLogin;
