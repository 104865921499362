import React from "react";
import PropTypes from "prop-types";

import { Tooltip as PrimeTooltip } from "primereact/tooltip";
import styled from "styled-components";
import {
  compose,
  space,
  layout,
  flexbox,
  position,
  typography,
  color,
} from "styled-system";

import Config from "@src/config";

import breakpoints from "@utils/breakpoints";

const mobileBreakPoint = Config.mobileBreakPoint;

const ToolTip = styled(PrimeTooltip)`
  ${compose(layout, space, flexbox, position, typography, color)};

  .p-tooltip-text,
  .p-tooltip-text > div {
    background-color: var(--white);
    border-radius: 10px;

    color: var(--grey-dark);
    font-size: var(--fs-h5);
    line-height: var(--lh-text-m);
    font-weight: var(--medium-weight);

    p {
      color: var(--grey-dark);
      font-size: var(--fs-h5);
      line-height: var(--lh-text-m);
      font-weight: var(--medium-weight);
    }

    strong {
      font-weight: var(--semibold-weight);
    }

    em {
      font-style: italic;
    }

    li {
      margin-left: 1.5rem;
    }

    ol,
    ul {
      list-style: disc;
    }

    @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
      overflow-y: scroll;
    }
  }

  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    position: relative;
    width: 80%;
  }
`;

const Tooltip = ({ content, isHtml = false, autoHide = false, ...props }) => {
  return (
    <ToolTip autoFocus autoHide={autoHide} {...props}>
      {isHtml ? (
        <div autoFocus dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        content
      )}
    </ToolTip>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  content: PropTypes.node,
  autoHide: PropTypes.bool,
  isHtml: PropTypes.bool,
};

export default Tooltip;
