import Handlebars from "handlebars";

// Function to clean up empty objects in arrays
function cleanEmptyObjects(dataArray) {
  if (!Array.isArray(dataArray)) return dataArray;

  return dataArray.filter(item => {
    // Check if item is an object and not empty
    if (typeof item === "object" && item !== null) {
      return Object.keys(item).length > 0;
    }

    // Retain items that are not objects
    return true;
  });
}

// Function to clean up all array properties in an object
function cleanObjectArrays(obj) {
  if (typeof obj !== "object" || obj === null) return obj;

  const cleanedObj = {};

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      cleanedObj[key] = cleanEmptyObjects(obj[key]);
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      cleanedObj[key] = cleanObjectArrays(obj[key]);
    } else {
      cleanedObj[key] = obj[key];
    }
  }

  return cleanedObj;
}

export function generateHTMLFromTemplate(template, data) {
  const cleanedData = cleanObjectArrays(data);
  const handlebars = Handlebars.compile(template);
  const html = handlebars(cleanedData);

  return html;
}
