import React from "react";
import { useIntl } from "react-intl";

import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";

const LoginOptionsSeparator = () => {
  const { messages } = useIntl();

  return (
    <Div width={1} display="flex" alignItems="center" gridGap={2}>
      <Div width={1} height={1} bg="var(--blue-logo)" />
      <TextMediumWeight color="var(--blue-logo)">
        {messages.label_alternative}
      </TextMediumWeight>
      <Div width={1} height={1} bg="var(--blue-logo)" />
    </Div>
  );
};

export default LoginOptionsSeparator;
