import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import { SurveyForm } from "@components/Survey";
import { useIntl } from "react-intl";
import updateRolesService from "@app/services/roles/updateRolesService";
import useRoleConfig from "./roleConfig";
import { getRolesServiceById } from "@app/services/roles/rolesService";
import permissionsService from "@app/services/permissions/permissionsService";
const StyledCreateBilling = styled.div`
  width: 1110px;
`;
const EditRoleDialog = ({ editRoleId, handleClose = () => {} }) => {
  const roleConfig = useRoleConfig();
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const surveyRef = useRef(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const [roleData, setRoleData] = useState([]);
  const fetchRoleData = async () => {
    const roleData = await getRolesServiceById(editRoleId);
    return roleData;
  };

  const fetchPermissionsData = async () => {
    const permissionResponse = await permissionsService();
    return permissionResponse.data.data;
  };

  const groupPermissionsByRole = permissionData => {
    return permissionData.reduce((acc, { group, id }) => {
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(id);
      return acc;
    }, {});
  };

  const filterAllowedPermissions = (groupedRoles, allowedPermissions) => {
    Object.keys(groupedRoles).forEach(group => {
      groupedRoles[group] = groupedRoles[group].filter(roleId =>
        allowedPermissions.includes(roleId)
      );
    });
    return groupedRoles;
  };

  const constructSingleRole = (roleData, groupedRoles) => {
    const allowedPermissions = roleData.permissions.map(({ id }) => id);
    const filteredRoles = filterAllowedPermissions(
      groupedRoles,
      allowedPermissions
    );

    return {
      name: roleData.name,
      portal: roleData.portal,
      ...filteredRoles,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [roleData, permissionData] = await Promise.all([
          fetchRoleData(),
          fetchPermissionsData(),
        ]);

        const groupedRoles = groupPermissionsByRole(permissionData);
        const singleRole = constructSingleRole(roleData, groupedRoles);

        setRoleData(singleRole);
      } catch (error) {
        setRoleData([]);
      }
    };
    fetchData();
  }, [editRoleId]);

  const handleUpdateCase = useCallback(
    async data => {
      try {
        setIsLoading(true);
        const combinedIds = Object.values(data)
          .filter(value => Array.isArray(value))
          .flat();
        const payload = {
          name: data.name,
          portal: data.portal,
          permissions: combinedIds,
        };
        await updateRolesService(editRoleId, payload);
        showSuccessToast(messages.label_role_updated);
        handleClose();
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [messages, showErrorToast, handleClose, showSuccessToast, editRoleId]
  );
  const handleCompleteSurvey = useCallback(
    survey => {
      handleUpdateCase(survey);
    },
    [handleUpdateCase]
  );
  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };
  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};
    handleUpdateCase(data);
  };
  return (
    <Div>
      {isLoading && <ProgressSpinner />}
      <FullSizeDialog title={messages.label_edit_role} onClose={handleClose}>
        <StyledCreateBilling>
          <Div bgColor="var(--white)">
            <SurveyForm
              data={roleData}
              formConfig={roleConfig}
              onComplete={handleCompleteSurvey}
              onRefLoaded={handleLoadSurveyRef}
            />
          </Div>

          {!isLoading && (
            <Div
              display="flex"
              flexDirection={["column", "column", "row", "row"]}
              gridGap={3}
              ml={4}
            >
              <PrimaryButton label={messages.label_save} onClick={handleSave} />
              <PrimaryButtonOutlined
                backgroundColor="transparent !important"
                minWidth="150px"
                label={messages.label_back}
                onClick={handleClose}
              />
            </Div>
          )}
        </StyledCreateBilling>
      </FullSizeDialog>
    </Div>
  );
};
EditRoleDialog.propTypes = {
  handleClose: PropTypes.func,
  editRoleId: PropTypes.string,
};
export default EditRoleDialog;
