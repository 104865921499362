import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { ButtonLinkIcon } from "@components/Button";
import Div from "@components/Div";
import { TextMediumWeight, Text, TextSemiBoldWeight } from "@components/Text";
import { FileUpload as PRFileUpload } from "primereact/fileupload";
import { REPORT_SIZE_LIMIT } from "@utils/constant";
import InputTextV2 from "@components/InputTextV2";
import Icon from "@components/Icon";
import { useIntl } from "react-intl";
import Link from "@components/Link";
import Span from "@components/Span";
import Chip from "@components/Chip";
import { formatDate } from "@utils/utils";

const FileUploadContainer = styled(Div)`
  margin-top: 16px;
`;

const ACCEPT_FILE_TYPES = [".doc",
  ".docx",
  ".pdf",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",];

const UploadBordered = ({ errorMessage = "", uploadRef, onFileAttached }) => {
  const { messages } = useIntl();
  const [error, setError] = useState("");

  const onError = () => {
    setError(errorMessage);
  };

  const handleOnSubmit = () => {
    const input = uploadRef.current.getInput();
    input.click();
  };

  const handleClear = () => {
    uploadRef.current.clear();
    onFileAttached({ files: [] });
  };

  const handleAttachFile = event => {
    const { files = [] } = event;
    if (!files.length) {
      uploadRef.current.clear();
      return;
    }

    const acceptedFileType = [
      ".doc",
      ".docx",
      ".pdf",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    
    const [attachedFile] = files;
    
    const fileExtension = attachedFile.name.split('.').pop().toLowerCase();
    
    if (!acceptedFileType.includes(attachedFile.type) && !acceptedFileType.includes(`.${fileExtension}`)) {
      uploadRef.current.clear();
      setError(messages.label_only_pdf_doc_allowed);
      return;
    }
    
    onFileAttached(event);
  };

  const renderEmptyTemplate = () => (
    <Div display="flex" flexDirection="row" gridGap={20} px={24} py={16}>
      <Link handleClick={handleOnSubmit}>
        <Icon name="pin" mr={1} color="var(--blue-dark) !important" />
        {messages.label_attach_file}
      </Link>
      <Text>{messages.text_drag_and_drop_here}</Text>
    </Div>
  );

  const renderProgressBar = () => <Div display="none" />;

  const renderItem = file => (
    <Div
      display="flex"
      flexDirection="row"
      bg="var(--blue-lightest)"
      borderRadius={20}
      justifyContent="space-between"
      alignItems="center"
      px={12}
      py={1}
      mx={24}
      my={16}
    >
      <Icon
        name="download-assignment"
        color="var(--turquoise)"
        fontSize="var(--fs-text-secondary)"
      />
      <TextSemiBoldWeight>{file.name}</TextSemiBoldWeight>
      <Icon
        name="close"
        color="var(--grey)"
        fontSize="var(--fs-grade-text)"
        onClick={handleClear}
        style={{
          cursor: "pointer",
        }}
      />
    </Div>
  );

  return (
    <>
      <PRFileUpload
        customUpload
        name="file"
        accept={ACCEPT_FILE_TYPES}
        maxFileSize={REPORT_SIZE_LIMIT}
        invalidFileSizeMessageDetail={messages.label_maximum_upload_size}
        invalidFileSizeMessageSummary={messages.label_invalid_file_size}
        ref={uploadRef}
        onError={onError}
        emptyTemplate={renderEmptyTemplate}
        headerStyle={{
          display: "none",
        }}
        progressBarTemplate={renderProgressBar}
        itemTemplate={renderItem}
        onSelect={handleAttachFile}
      />
      {error && (
        <Div>
          <Span error py={2}>
            {error}
          </Span>
        </Div>
      )}
    </>
  );
};

UploadBordered.propTypes = {
  errorMessage: PropTypes.string,
  uploadRef: PropTypes.object,
  onFileAttached: PropTypes.func,
};

const FileUploadItem = ({
  index,
  file,
  onFileChange,
  onDescriptionChange,
  onRemove,
  errorMessage,
}) => {
  const uploadRef = useRef(null);
  const { messages } = useIntl();

  const onFileAttached = event => {
    if (event.files.length > 0) {
      onFileChange(index, event.files[0]);
    }
  };

  return (
    <Div
      display="grid"
      gridTemplateColumns={file?.created_at ? "3.5fr 3fr 160px 20px" : "3.5fr 3fr 20px"}
      gridGap="16px"
      alignItems="center"
      key={index}
      mt={4}
    >
      <>
        {file.file_id && (
          <Chip
            label={file.file.file_name}
            icon={"icon-pin"}
            removeIcon={"icon-download-assignment"}
            onClick={() => {
              window.open(file.file?.url);
            }}
          />
        )}
        {!file.file_id && (
          <UploadBordered
            uploadRef={uploadRef}
            errorMessage={errorMessage}
            category={`file-${index}`}
            onFileAttached={onFileAttached}
            file={file}
          />
        )}
      </>
      <InputTextV2
        mt={2}
        placeholder={messages.label_description_required}
        value={file.document_description}
        onChange={e => onDescriptionChange(index, e.target.value)}
      />
      {file?.created_at && (
        <TextMediumWeight>
          {file?.created_at ? formatDate(file?.created_at) : ""}
        </TextMediumWeight>
      )}
      <Icon
        onClick={() => onRemove(index)}
        name="menu-trash"
        color="var(--turquoise)"
        fontSize="20px"
      />
    </Div>
  );
};

FileUploadItem.propTypes = {
  index: PropTypes.number.isRequired,
  file: PropTypes.object,
  onFileChange: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

const MultiFileUpload = ({ label, name }) => {
  const { setFieldValue,setFieldError, errors, values } = useFormikContext();

  const [fileUploads, setFileUploads] = useState(
    (values[name] && values[name].length > 0) ? values[name] : [{ file: null, document_description: "" }]
  );
  
  
  const { messages } = useIntl();
  const handleAddFile = () => {
    const newUploads = [
      ...fileUploads,
      { file: null, document_description: "" },
    ];
    setFileUploads(newUploads);
    setFieldValue(name, newUploads);
  };

  const handleFileChange = (index, newFile) => {
    const updatedUploads = [...fileUploads];
    updatedUploads[index].file = newFile;
    setFileUploads(updatedUploads);
    setFieldValue(name, updatedUploads);
  };

  const handleDescriptionChange = (index, newDescription) => {
    const updatedUploads = [...fileUploads];
    if(updatedUploads[index].file){
      updatedUploads[index].document_description = newDescription;
      setFileUploads(updatedUploads);
      setFieldValue(name, updatedUploads);
    }else{
      setFieldError(`[${index}]`,messages.label_no_document_uploaded);
    }
  };

  const handleRemoveFile = index => {
    const updatedUploads = fileUploads.filter((_, i) => i !== index);
    setFileUploads(updatedUploads);
    setFieldValue(name, updatedUploads);
  };

  return (
    <FileUploadContainer>
      <TextMediumWeight>{label}</TextMediumWeight>
      {fileUploads.map((file, index) => (
        <>
          <FileUploadItem
            key={file.id || `key_file_${index}`}
            index={index}
            file={file}
            onFileChange={handleFileChange}
            onDescriptionChange={handleDescriptionChange}
            onRemove={handleRemoveFile}
            errorMessage={errors[name]?.[index]?.file || ""}
          />
          {errors && (
            <Div>
              <Span error py={2}>
                {errors[index]}
              </Span>
            </Div>
          )}
        </>
      ))}

      <ButtonLinkIcon
        type="button"
        onClick={handleAddFile}
        style={{ marginTop: "8px" }}
      >
        <Icon
          name="plus"
          mr={2}
          color="var(--turquoise)"
          fontSize="var(--fs-icon-m)"
        />
        {messages.admin_customer_document_card_add_document}
      </ButtonLinkIcon>
    </FileUploadContainer>
  );
};

MultiFileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default MultiFileUpload;
