import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { loadActiveSubscriptionData } from "@utils/common";
import ActiveSubscription from "../userProfile/ActiveSubscription";
import SubcsriptionCard from "../userProfile/SubcsriptionCard";
import { useHistory } from "react-router-dom";
import { SUBSCRIPTION_STATUS } from "@utils/constant";
import { useIntl, FormattedMessage } from "react-intl";
import UserContainer from "@layout/UserContainer";
import { loadServicesData } from "@utils/common";
import EditFormDialog from "@components/EditFormDialog";
import { FORM_INPUT_TYPE } from "@utils/enum";
import createSubscriptionRequest from "@app/services/subscription/createSubscriptionRequest";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import Breadcrumb from "@components/Breadcrumb";
import SubscriptionOrders from "./SubscriptionOrders";
import Tabs from "@components/Tabs";

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required(
    <FormattedMessage id="validation_valid_email_input" />
  ),
  phone: Yup.string()
    .typeError(<FormattedMessage id="validation_valid_phone_number" />)
    .required(<FormattedMessage id="validation_empty_phone_number" />),
  name: Yup.string().required(<FormattedMessage id="validation_empty_name" />),
  product_id: Yup.string().required(
    <FormattedMessage id="validation_empty_name" />
  ),
});

const getSubscriptionConfig = (data, packages, productId) => {
  const packageOptions = packages.map(o => ({
    label: o?.name,
    value: o?.id,
  }));

  const config = {
    details: [
      {
        key: "product_id",
        translationKey: "label_desired_subscription",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: packageOptions,
        selectorField: "label",
        value: Number(productId?.id),
      },
      {
        key: "name",
        translationKey: "contact_person_name",
        type: FORM_INPUT_TYPE.TEXT,
        textType: FORM_INPUT_TYPE.TEXT,
        value: data.name,
        required: true,
      },
      {
        key: "email",
        translationKey: "label_email",
        type: FORM_INPUT_TYPE.TEXT,
        textType: FORM_INPUT_TYPE.TEXT,
        value: data.email,
        required: true,
      },
      {
        key: "phone",
        translationKey: "label_phone",
        type: FORM_INPUT_TYPE.TEXT,
        textType: FORM_INPUT_TYPE.TEXT,
        value: data?.phone,
        required: true,
      },
      {
        key: "message",
        translationKey: "title_message",
        type: FORM_INPUT_TYPE.TEXTAREA,
        value: data.sample_name,
        fullWidth: true,
      },
    ],
  };
  return config;
};

const Subscription = () => {
  const { messages } = useIntl();
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const { customer = {} } = userInfo;
  const { id: customer_id = "" } = customer;
  const [activePackage, setActivePackage] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [getActivityLog, setGetActivityLog] = useState(false);
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);
  const [showCreateSubscriptionModal, setShowCreateSubscriptionModal] =
    useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const history = useHistory();
  const [productId, setProductId] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  const user = {
    name: userInfo?.name,
    email: userInfo?.email,
    phone: userInfo?.phone,
  };

  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    try {
      let package_list = loadServicesData(customer_id);
      package_list.then(result => {
        setSubscriptionPackages(result);
      });
    } catch (e) {
      //catch error
    }
  }, [customer_id]);

  useEffect(() => {
    (async () => {
      if (!activePackage) {
        setIsFetching(true);
        const currentPckg = await loadActiveSubscriptionData(userInfo);
        setActivePackage(currentPckg || {});
        const { status = null, product_id } = currentPckg || {};
        setProductId(product_id);
        const { orders = [] } = currentPckg || {};
        setGetActivityLog(orders);
        setSubscriptionStatus(status);
        setIsFetching(false);
      }
    })();
  }, [userInfo, history, activePackage]);
  let headerConfig = {
    title: messages.subscription,
  };

  const handleCreateSubscriptionrequest = async values => {
    setIsFetching(true);
    try {
      await createSubscriptionRequest(values);
      setActivePackage(false);
      showSuccessToast(messages.message_subscription_request_created);
    } catch (e) {
      setIsFetching(false);
      showErrorToast(messages.error_try_later);
    }
  };

  const handleShowCreateSubscriptionModal = useCallback(
    (id = "") => {
      if (id) {
        setProductId(id);
      }
      setShowCreateSubscriptionModal(!showCreateSubscriptionModal);
    },
    [showCreateSubscriptionModal]
  );

  const { details } = getSubscriptionConfig(
    { ...activePackage, ...user },
    subscriptionPackages,
    productId
  );

  const breadCrumbItems = [
    {
      label: messages.my_profile,
    },
    { label: messages.subscriptions_title },
  ];

  const renderSubscription = useCallback(
    () =>
      isFetching ? (
        <ProgressSpinner />
      ) : (
        <>
          {subscriptionStatus === SUBSCRIPTION_STATUS.TERMINATED && (
            <SubcsriptionCard
              userInfo={userInfo}
              activePackage={activePackage}
              activityLogs={getActivityLog}
              terminated={true}
              onCreateSubscription={handleShowCreateSubscriptionModal}
            />
          )}
          {subscriptionStatus &&
            subscriptionStatus !== SUBSCRIPTION_STATUS.TERMINATED && (
              <ActiveSubscription
                activePackage={activePackage}
                subscriptionPackages={subscriptionPackages}
                onCreateSubscription={handleShowCreateSubscriptionModal}
              />
            )}
          {typeof activePackage === "object" &&
            Object.keys(activePackage).length === 0 && (
              <SubcsriptionCard
                userInfo={userInfo}
                activePackage={false}
                activityLogs={getActivityLog}
                onCreateSubscription={handleShowCreateSubscriptionModal}
              />
            )}
        </>
      ),
    [
      activePackage,
      getActivityLog,
      handleShowCreateSubscriptionModal,
      isFetching,
      subscriptionPackages,
      subscriptionStatus,
      userInfo,
    ]
  );

  const handleTabChange = event => {
    const { index } = event;
    history.push({
      pathname: TAB_CONTENT[index]?.url,
    });
  };

  const TAB_CONTENT = useMemo(
    () => [
      {
        title: messages.subscription,
        content: renderSubscription(),
        url: `/mittkonto/abonnemang`,
      },
      {
        title: messages.title_orders,
        content: <SubscriptionOrders />,
        url: `/mittkonto/abonnemang/orders`,
      },
    ],
    [messages.subscription, messages.title_orders, renderSubscription]
  );

  useEffect(() => {
    const {
      location: { pathname },
    } = history;
    const activeTabIndex = TAB_CONTENT.findIndex(item => item.url === pathname);
    setActiveIndex(activeTabIndex);
  }, [TAB_CONTENT, history]);

  return (
    <UserContainer config={headerConfig}>
      <Breadcrumb p={0} mb={3} items={breadCrumbItems} />
      <Tabs
        content={TAB_CONTENT}
        onTabChange={handleTabChange}
        activeIndex={activeIndex}
      />
      {showCreateSubscriptionModal && (
        <EditFormDialog
          onHide={handleShowCreateSubscriptionModal}
          onSubmit={handleCreateSubscriptionrequest}
          title={messages.title_subscription_request}
          description={messages.description_subscription_request}
          validationSchema={ValidationSchema}
          config={details}
        />
      )}
    </UserContainer>
  );
};

export default Subscription;
