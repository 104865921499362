import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import styled from "styled-components";

import Currency from "@components/Currency";
import Div from "@components/Div";
import Icon, { SmallEllipseIcon, BundleIcon } from "@components/Icon";
import Link, { LinkV2 } from "@components/Link";
import { TextSmall, TextLargeSemiBoldWeight } from "@components/Text";
import Tooltip from "@components/ToolTip";

import useAuthorization from "@hooks/useAuthorization";

import { CustomerPermissions, AdminPermissions } from "@src/enum/Permissions";

import { BUNDLE, ROUTES } from "@utils/constant";
import { diffDays } from "@utils/utils";

import ReportResultCard from "./ReportResultCard";

import { CASES_DATA_STATUS_TYPE, CASES_STATUS_TYPE } from "@utils/enum";

const StyledDiv = styled(Div)`
  :empty {
    display: none;
  }
`;

const CaseStatus = {
  [CASES_STATUS_TYPE.PENDING]: (
    <FormattedMessage id={`case_status_type_${CASES_STATUS_TYPE.PENDING}`} />
  ),
  [CASES_STATUS_TYPE.MANUAL]: (
    <FormattedMessage id={`case_status_type_${CASES_STATUS_TYPE.MANUAL}`} />
  ),
  [CASES_STATUS_TYPE.COMPLETED]: (
    <FormattedMessage id={`case_status_type_${CASES_STATUS_TYPE.COMPLETED}`} />
  ),
  [CASES_STATUS_TYPE.IN_PROGRESS]: (
    <FormattedMessage
      id={`case_status_type_${CASES_STATUS_TYPE.IN_PROGRESS}`}
    />
  ),
};

const DELIVERY_IMMEDIATE = "Direkt";

const OrderItemCard = ({
  orderItem,
  product,
  report,
  isOrderDetails,
  isOrderConfirmation,
}) => {
  const { messages } = useIntl();
  const { hasAllPermissions, isAdmin, hasAnyPermissions } = useAuthorization();

  const hasPricesPermission = hasAnyPermissions([
    CustomerPermissions.CustomerReadPrices,
    AdminPermissions.AdminReadPrices,
  ]);
  const hasCasePermission = hasAllPermissions([
    AdminPermissions.AdminReadCases,
  ]);
  const hasReportPermission = hasAnyPermissions([
    AdminPermissions.AdminReadReportAnalysis,
    CustomerPermissions.CustomerReadReportAnalysis,
    CustomerPermissions.CustomerReadSelfReportsAnalysis,
  ]);
  const hasReportAnalysisPermission = hasAnyPermissions([
    AdminPermissions.AdminReadReportAnalysis,
    CustomerPermissions.CustomerGradesReportAnalysis,
    CustomerPermissions.CustomerReadReportAnalysis,
    CustomerPermissions.CustomerReadSelfReportsAnalysis,
  ]);

  const {
    service_type: serviceType = "",
    price_after_adjustments: orderPrice = 0,
    credits: orderCredits = 0,
    name: service = "",
    case_id,
  } = orderItem;

  const {
    description: serviceDetail,
    delivery_time = "",
    id: productID,
  } = product || {};

  const { id: reportId, expired_at: reportExpiryDate = "" } = report || {};
  const history = useHistory();

  const adminReportPageURL = `${ROUTES.ADMIN_ANALYSIS_LIST.URL}/${reportId}`;
  const caseDetailsURL = ROUTES.ADMIN_CASE_DETAILS.URL.replace(
    ":case_id",
    case_id
  );

  const handleOnReportDetails = useCallback(
    () =>
      isAdmin
        ? history.push(`${ROUTES.ADMIN_ANALYSIS_LIST.URL}/${reportId}`)
        : window.open(`${ROUTES.ORDER_RESULTS.URL}?id=${reportId}`, "_blank"),
    [isAdmin, history, reportId]
  );

  // This is need and only in order details
  // showExpiry and it will be shown only when compeleted
  const getExpiryMessage = () => {
    let expiryDaysCount = "";
    expiryDaysCount = diffDays(reportExpiryDate);
    if (!expiryDaysCount) return "";
    if (expiryDaysCount > 0)
      return `${messages.label_expires_in} ${expiryDaysCount} ${messages.label_days}`;
  };

  const renderExpiredDays = () => {
    return <TextSmall ml={4}>{getExpiryMessage()}</TextSmall>;
  };

  const renderPrice = () => {
    return (
      <TextLargeSemiBoldWeight
        fontSize={[
          "var(--fs-text-m) !important",
          "var(--fs-text-m) !important",
          "var(--fs-tex)",
          "var(--fs-tex)",
        ]}
      >
        {orderCredits > 0 && messages.prepaid_price_label}
        {orderCredits === 0 && <Currency value={orderPrice} />}
      </TextLargeSemiBoldWeight>
    );
  };

  const deliveryValueByStatus =
    orderItem.case_status !== "completed"
      ? messages.order_item_card_two_business_days
      : messages.order_item_card_delivered;

  return (
    <>
      <Div
        display="flex"
        alignItems={["flex-start", "center"]}
        justifyContent="space-between"
      >
        <Div display="flex" alignItems="center" flex={1}>
          <Div display="flex" flexDirection={["column", "row"]}>
            <Div
              display={"flex"}
              width={["200px", "100%"]}
              alignItems={["baseline", "center"]}
            >
              <TextLargeSemiBoldWeight
                fontSize={[
                  "var(--fs-text-m) !important",
                  "var(--fs-text-m) !important",
                  "var(--fs-tex)",
                  "var(--fs-tex)",
                ]}
                whiteSpace="nowrap"
              >
                {service}
                {serviceType === BUNDLE && (
                  <BundleIcon ml={2} name="bundle-tag"></BundleIcon>
                )}
                <SmallEllipseIcon
                  ml={2}
                  name="info"
                  className={`description_${productID}`}
                >
                  <Tooltip
                    target={`.description_${productID}`}
                    content={serviceDetail}
                    isHtml={true}
                  ></Tooltip>
                </SmallEllipseIcon>
              </TextLargeSemiBoldWeight>
            </Div>
          </Div>

          {isOrderDetails && (
            <TextSmall ml={[0, 4]}>
              {CaseStatus[orderItem.case_status?.replace("-", "_")]}
            </TextSmall>
          )}

          {isOrderDetails && (
            <Div display={["none", "flex"]}>{renderExpiredDays()}</Div>
          )}
        </Div>

        {isOrderConfirmation && (
          <Div
            display="flex"
            justifyContent="flex-end"
            minWidth={["0px", "150px"]}
          >
            <TextLargeSemiBoldWeight
              fontSize={[
                "var(--fs-text-m) !important",
                "var(--fs-text-m) !important",
                "var(--fs-tex)",
                "var(--fs-tex)",
              ]}
              textAlign="left"
            >
              {delivery_time === DELIVERY_IMMEDIATE
                ? deliveryValueByStatus
                : delivery_time}
            </TextLargeSemiBoldWeight>
          </Div>
        )}

        {isOrderDetails && hasPricesPermission && (
          <Div
            display="flex"
            justifyContent="flex-end"
            minWidth={["0px", "125px"]}
          >
            {renderPrice()}
          </Div>
        )}
      </Div>
      <>
        {orderItem.case_status === "completed" &&
          report &&
          hasReportAnalysisPermission && <ReportResultCard report={report} />}

        <StyledDiv display="flex" gridGap="24px" mt="24px">
          {hasReportPermission &&
            orderItem.case_status === "completed" &&
            (isAdmin ? (
              <LinkV2 to={adminReportPageURL}>
                {messages.view_report}
                <Icon ml={1} name="headerarrowright" />
              </LinkV2>
            ) : (
              <Link handleClick={handleOnReportDetails}>
                {messages.view_report}
                <Icon ml={1} name="headerarrowright" />
              </Link>
            ))}

          {hasCasePermission && (
            <LinkV2 to={caseDetailsURL}>
              {messages.label_case_details}{" "}
              <Icon ml={1} name="headerarrowright" />
            </LinkV2>
          )}
        </StyledDiv>

        {delivery_time === DELIVERY_IMMEDIATE &&
          (orderItem.data_source_status === CASES_DATA_STATUS_TYPE.MANUAL ? (
            <Div my={3}>
              <TextSmall>
                {messages.order_item_card_could_no_create_report}
              </TextSmall>
            </Div>
          ) : !orderItem.case_status ||
            orderItem.case_status === CASES_STATUS_TYPE.IN_PROGRESS ? (
            <Div my={3}>
              <TextSmall>
                {messages.order_item_card_could_case_in_progress}
              </TextSmall>
            </Div>
          ) : (
            <></>
          ))}
      </>
    </>
  );
};

OrderItemCard.propTypes = {
  orderItem: PropTypes.object,
  product: PropTypes.object,
  report: PropTypes.object,
  isOrderDetails: PropTypes.bool,
  isOrderConfirmation: PropTypes.bool,
};

export default OrderItemCard;
