import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
  PrimaryButton,
  PrimaryButtonIconOutlined,
  PrimaryButtonOutlined,
} from "@components/Button";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import { SurveyForm } from "@components/Survey";

import ReportPreview from "./ReportPreview";

const ReportFormComponent = ({
  isLoading,
  isReportCreated,
  isSurveyEditable,
  surveyMode,
  reportData,
  reportConfig,
  onToggleFullScreenMode,
  onComplete,
  onRefLoaded,
  onCreateReport,
  onSave,
  onOpenPreviewDialog,
  onUpdatePreview,
}) => {
  const { messages } = useIntl();

  const config = reportConfig ? JSON.parse(reportConfig) : {};

  return (
    <>
      <Div className="report-form">
        <Div className="buttons-container">
          <span>&nbsp;</span>
          <Icon
            p={1}
            name="full-screen"
            cursor="pointer"
            color="var(--blue-dark)"
            fontSize="var(--fs-icon-m)"
            backgroundColor="var(--white)"
            border="1px solid var(--grey-lightest)"
            borderRadius="10px"
            onClick={onToggleFullScreenMode}
          />
        </Div>

        <Div className="report-form-container">
          <Div className="form-container">
            <H3
              textTransform="none"
              color="var(--blue-dark) !important"
            >{`${messages.label_form}:`}</H3>
            <Div
              borderRadius="10px"
              mt={3}
            >
              <SurveyForm
                mode={surveyMode}
                data={reportData}
                formConfig={config}
                onComplete={onComplete}
                onRefLoaded={onRefLoaded}
                onValueChanged={onUpdatePreview}
              />
            </Div>
          </Div>

          <Div className="preview-container">
            <H3
              textTransform="none"
              color="var(--blue-dark) !important"
            >{`${messages.label_report_preview}:`}</H3>
            <Div
              border="1px solid var(--grey-lightest)"
              borderRadius="10px"
              mt={3}
            >
              <ReportPreview reportConfig={config} reportData={reportData} />
            </Div>
          </Div>
        </Div>
      </Div>

      {!isLoading && !isReportCreated && (
        <Div
          className="buttons-footer"
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          gridGap={3}
        >
          {!isReportCreated && isSurveyEditable && (
            <PrimaryButton
              label={messages.label_create_report}
              onClick={onCreateReport}
            />
          )}

          {!isReportCreated && isSurveyEditable && (
            <PrimaryButtonOutlined
              backgroundColor="transparent !important"
              minWidth="150px"
              label={messages.label_save}
              onClick={onSave}
            />
          )}

          {!isReportCreated && (
            <PrimaryButtonIconOutlined
              id="button-preview"
              label="Preview report"
              icon={<Icon mr={1} name="file-download" />}
              onClick={onOpenPreviewDialog}
            />
          )}
        </Div>
      )}
    </>
  );
};

ReportFormComponent.propTypes = {
  isLoading: PropTypes.bool,
  isReportCreated: PropTypes.bool,
  isSurveyEditable: PropTypes.bool,
  surveyMode: PropTypes.string,
  reportData: PropTypes.object,
  reportConfig: PropTypes.string,
  onToggleFullScreenMode: PropTypes.func,
  onComplete: PropTypes.func,
  onRefLoaded: PropTypes.func,
  onCreateReport: PropTypes.func,
  onSave: PropTypes.func,
  onOpenPreviewDialog: PropTypes.func,
  onUpdatePreview: PropTypes.func,
};

export default ReportFormComponent;
