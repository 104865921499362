import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import {
  layout,
  compose,
  space,
  flexbox,
  width,
  position,
  typography,
  color,
} from "styled-system";
import Div from "@components/Div";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";
import { Link as RouterLink } from "react-router-dom";

const mobileBreakPoint = Config.mobileBreakPoint;
const StyledLink = styled.a`
  ${compose(layout, space, flexbox, width, position, typography, color)};
  color: var(--blue-dark);
  cursor: pointer;
  font-size: ${({ fontSize = "var(--fs-link-m)" }) => fontSize};
  font-weight: var(--semibold-weight);
  line-height: var(--lh-link);
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  i {
    display: flex;
    align-self: center;
    color: ${({ darkblue }) =>
      darkblue ? "var(--blue-dark)" : "var(--turquoise)"};
  }
  &:hover {
    i,
    span {
      color: var(--blue-medium) !important;
    }
  }

  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    font-size: var(--fs-link-m);
    line-height: var(--lh-link-m);
  }
  white-space: ${({ whiteSpace }) => whiteSpace || "inherit"};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const StyledDiv = styled(Div)`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    white-space: initial !important;
  }
`;

function Link(props) {
  const {
    href,
    label,
    children,
    iconPos = "right",
    handleClick,
    divDisplay,
  } = props;

  const ContentBasedOnDirection = () => (
    <StyledDiv
      display={divDisplay || "flex"}
      flexDirection={"row"}
      onClick={handleClick}
      {...props}
      alignItems={"center"}
    >
      {iconPos === "left" && children}
      {label}
      {iconPos === "right" && children}
    </StyledDiv>
  );

  return (
    <StyledLink href={href} {...props}>
      <ContentBasedOnDirection />
    </StyledLink>
  );
}
export const LinkArrow = styled(Link)`
  flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  white-space: ${({ whiteSpace }) => whiteSpace || "wrap"} !important;
  &:hover {
    i {
      transform: translateX(5px);
    }
  }
`;

Link.propTypes = {
  href: Proptypes.string,
  label: Proptypes.string,
  children: Proptypes.node,
  iconPos: Proptypes.string,
  handleClick: Proptypes.func,
  divDisplay: Proptypes.string,
};
export default Link;

export const LinkV2 = styled(RouterLink)`
  ${compose(layout, space, flexbox, width, position, typography, color)};
  color: var(--blue-dark);
  cursor: pointer;
  text-decoration: none;
  white-space: "inherit";
  pointer-events: "auto";
  font-size: var(--fs-link-m);
  font-weight: var(--semibold-weight);
  line-height: var(--lh-link);
  display: inline-flex;

  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    font-size: var(--fs-link-m);
    line-height: var(--lh-link-m);
  }
  i {
    display: flex;
    align-self: center;
  }
  &:hover {
    color: var(--blue-medium);
  }
`;
