import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import { SurveyForm } from "@components/Survey";
import { useIntl } from "react-intl";
import createPermissionService from "@app/services/permissions/createPermissionsService";
import permissionsConfig from "./permissionsConfig.json";

const StyledCreateBilling = styled.div`
  width: 1110px;
`;

const CreatePermissionDialog = ({ handleClose = () => {} }) => {
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const surveyRef = useRef(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const handleUpdateCase = useCallback(
    async data => {
      try {
        setIsLoading(true);
        await createPermissionService(data);
        showSuccessToast(messages.label_permission_added);
        handleClose();
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [messages, showErrorToast, handleClose, showSuccessToast]
  );
  const handleCompleteSurvey = useCallback(
    survey => {
      handleUpdateCase(survey);
    },
    [handleUpdateCase]
  );

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};
    handleUpdateCase(data);
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}
      <FullSizeDialog
        title={messages.labe_add_permission}
        onClose={handleClose}
      >
        <StyledCreateBilling>
          <Div bgColor="var(--white)">
            <SurveyForm
              formConfig={permissionsConfig}
              onComplete={handleCompleteSurvey}
              onRefLoaded={handleLoadSurveyRef}
            />
          </Div>
          {!isLoading && (
            <Div
              display="flex"
              flexDirection={["column", "column", "row", "row"]}
              gridGap={3}
              ml={4}
            >
              <PrimaryButton label={messages.label_save} onClick={handleSave} />
              <PrimaryButtonOutlined
                backgroundColor="transparent !important"
                minWidth="150px"
                label={messages.label_back}
                onClick={handleClose}
              />
            </Div>
          )}
        </StyledCreateBilling>
      </FullSizeDialog>
    </Div>
  );
};

CreatePermissionDialog.propTypes = {
  handleClose: PropTypes.func,
};

export default CreatePermissionDialog;
