import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import OrderDetails from "../../shared/orderDetails/OrderDetails";
import { ROUTES } from "@utils/constant";
import { default as getDetails } from "@app/services/orders/fetchOrderDetails";
import ProgressSpinner from "@components/ProgressSpinner";
import UserContainer from "@layout/UserContainer";
import { useIntl } from "react-intl";

const UserOrderDetails = () => {
  const { search = "" } = useLocation();
  const [orderData, setOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { customer: { id: customerId = "" } = {} } = userInfo;

  useEffect(() => {
    const { id: orderId = "" } = queryString.parse(search);
    const getOrderDetails = () => {
      setIsLoading(true);
      getDetails(orderId).then(
        (res) => {
          setOrderData(res);
          setIsLoading(false);
        },
        (e) => {
          throw new Error(e);
        },
      );
    };
    getOrderDetails();
  }, [search]);

  const headerConfig = {
    title: messages.label_order + " #" + orderData.order_id,
  };

  return (
    <UserContainer config={headerConfig} isLoading={isLoading}>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <OrderDetails
          orderSummaryProps={orderData}
          redirectTo={ROUTES.ORDERS_LIST.URL}
          customerId={customerId}
          isCustomer
          onUpdateOrderSummaryProps={setOrderData}
        /> 
      )}
    </UserContainer>
  );
};

export default UserOrderDetails;
