import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import CardWrapper from "@pages/admin/users/components/CardWrapper";
import { Formik, Form } from "formik";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import JsonForm from "@components/JsonForm/JsonForm";
import * as Yup from "yup";
import createNewProduct from "@app/services/services/createNewProduct";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";

const StyledCreateBilling = styled.div`
  max-width: 1110px;
`;

const EditSubscriptionPackageDialog = ({ handleClose }) => {
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();

  const subscriptionPackageInformation = [
    {
      name: "name",
      label: messages.label_subscription_name,
      fieldType: "text",
      placeholder: messages.label_subscription_name,
      validation: Yup.string().required(messages.error_product_name),
    },
    {
      name: "price",
      label: messages.watchlist_label_price,
      fieldType: "text",
      placeholder: messages.watchlist_label_price,
      validation: Yup.number()
        .typeError(messages.error_price_integer)
        .integer(messages.error_price_integer)
        .min(0, messages.error_price_integer)
        .required(messages.error_price_validation),
    },
    {
      name: "credits",
      label: messages.label_credits_in_package,
      fieldType: "text",
      placeholder: messages.label_credits_in_package,
      validation: Yup.number()
        .typeError(messages.error_credits_integer)
        .integer(messages.error_credits_integer)
        .min(0, messages.error_credits_integer)
        .required(messages.error_price_credits),
    },
    {
      name: "discount",
      label: messages.label_discount_percentage,
      fieldType: "text",
      placeholder: messages.label_discount_percentage,
      validation: Yup.number()
        .typeError(messages.text_invalid_discount)
        .integer(messages.error_type_discount)
        .min(0, messages.error_type_discount)
        .required(messages.error_discount),
    },
  ];
  const descInformation = [
    {
      name: "description",
      label: messages.label_description,
      fieldType: "textarea",
      validation: Yup.string().required(messages.error_product_description),
    },
  ];

  const initialValues = {
    name: "",
    price: "",
    description: "",
    credits: null,
    state: "active",
    type: "subscription",
  };

  const formLayout = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gridColumnGap: 2,
  };

  const handleFormSubmit = async values => {
    values.purchase_by_credits = true;
    values.price_credits = values.price;
    try {
      await createNewProduct(values);
      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast("Failed to create new product. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <FullSizeDialog
        title={messages.title_create_subscription}
        onClose={handleClose}
      >
        <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
          {({ isSubmitting, dirty, isValid }) => (
            <StyledCreateBilling>
              <Form>
                <Div display="flex" flexDirection="column" >
                  <CardWrapper title={messages.label_details}>
                    <JsonForm
                      config={subscriptionPackageInformation}
                      formLayout={formLayout}
                    />
                  </CardWrapper>

                  <CardWrapper title={messages.label_description}>
                    <JsonForm
                      config={descInformation}
                      formLayout={formLayout}
                    />
                  </CardWrapper>

                  <Div
                    display="flex"
                    flexDirection={["column", "column", "row", "row"]}
                    alignItems="center"
                    justifyContent="center"
                    gridGap={4}
                  >
                    <PrimaryButtonOutlined
                      width={[1, 1, "150px", "150px"]}
                      label={messages.label_cancel}
                      onClick={handleClose}
                    />
                    <PrimaryButton
                      width={[1, 1, "150px", "150px"]}
                      label={messages.label_save}
                      type="submit"
                      disabled={!dirty || isSubmitting || !isValid}
                    />
                  </Div>
                </Div>
              </Form>
            </StyledCreateBilling>
          )}
        </Formik>
      </FullSizeDialog>
    </Div>
  );
};
EditSubscriptionPackageDialog.propTypes = {
  handleClose: PropTypes.func,
};
export default EditSubscriptionPackageDialog;
