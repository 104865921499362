import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import bankIDAuthService from "@app/services/bank-id/bankIDAuth";
import bankIDQRCodeService from "@app/services/bank-id/bankIDQRCode";
import bankIDCollectService from "@app/services/bank-id/bankIDCollect";

import { BANKID_UNIVERSAL_LINK } from "@utils/constant";
import { isMobile } from "@utils/utils";

const TIMEOUT = 30;
const INITIAL_FEEDBACK = {
  message_term: "",
  status: "",
  ssn: "",
};

const redirectToBankIdApp = autostartToken => {
  setTimeout(() => {
    const baseUrl = isMobile
      ? BANKID_UNIVERSAL_LINK.MOBILE
      : BANKID_UNIVERSAL_LINK.DESKTOP;
    const userAgent =
      navigator?.userAgent || navigator?.vendor || window?.opera;
    let redirectUrl = "null";
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      redirectUrl = encodeURIComponent(`${window.location}#h`);
    }
    window.open(
      `${baseUrl}?autostarttoken=${autostartToken}&redirect=${redirectUrl}`,
      "_self"
    );
  }, 0);
};

const clearIntervalRef = intervalRef => {
  const { qrCode, collect } = intervalRef.current;

  clearInterval(qrCode);
  clearInterval(collect);

  intervalRef.current = {};
};

export const useBankIDLogin = ({
  scope,
  candidateId,
  userNonVisibleData,
  onError,
}) => {
  const { messages } = useIntl();

  const [orderRef, setOrderRef] = useState("");
  const [qrCodeData, setQRCodeData] = useState("");
  const [bankIdFeedback, setBankIdFeedback] = useState(INITIAL_FEEDBACK);
  const [isUseQRCode, setIsUseQRCode] = useState(false);

  const seconds = useRef(0);
  const intervalRef = useRef({});

  const isCandidateLoginFlow = useMemo(() => !!candidateId, [candidateId]);

  const fetchOrderRef = async () => {
    const authPayload = {
      scope,
    };

    if (isCandidateLoginFlow) {
      authPayload.candidate_id = candidateId;
    }

    if (userNonVisibleData) {
      authPayload.userNonVisibleData = userNonVisibleData;
    }

    const response = await bankIDAuthService(authPayload);

    return response.data;
  };

  const onUseQrCode = async () => {
    try {
      const { qr_code = "", order_reference = "" } = await fetchOrderRef();

      setOrderRef(order_reference);
      setQRCodeData(qr_code);
      setIsUseQRCode(true);
    } catch (error) {
      onError?.(error.response?.data);
    }
  };

  const onUseBankIdApp = async () => {
    try {
      const { order_reference = "", autostart_token = "" } =
        await fetchOrderRef();

      setOrderRef(order_reference);
      setIsUseQRCode(false);

      redirectToBankIdApp(autostart_token);
    } catch (error) {
      onError?.(error?.message ?? messages.exception_error_message);
    }
  };

  const onCancelBankId = () => {
    clearIntervalRef(intervalRef);
    setQRCodeData(null);
    setOrderRef(null);
  };

  useEffect(() => {
    if (!orderRef) {
      return;
    }

    try {
      if (isUseQRCode) {
        const fetchQrCodeData = async () => {
          const response = await bankIDQRCodeService({
            order_reference: orderRef,
          });

          setQRCodeData(response.data?.qr_code);
        };

        intervalRef.current.qrCode = setInterval(() => {
          seconds.current += 1;

          if (seconds.current >= TIMEOUT) {
            clearIntervalRef(intervalRef);

            setQRCodeData(null);
            setOrderRef(null);
          }

          fetchQrCodeData();
        }, 1000);
      }

      const fetchCollect = async () => {
        const collectPayload = {
          order_reference: orderRef,
          scope,
        };

        const { data: feedbackData = {} } =
          await bankIDCollectService(collectPayload);

        if (feedbackData?.status !== "pending") {
          clearIntervalRef(intervalRef);
        }

        setBankIdFeedback({
          ...feedbackData,
          orderRef: orderRef,
        });
      };

      intervalRef.current.collect = setInterval(() => {
        fetchCollect();
      }, 2000);

      return () => {
        clearIntervalRef(intervalRef);
      };
    } catch (error) {
      clearIntervalRef(intervalRef);
    }
  }, [
    isCandidateLoginFlow,
    isUseQRCode,
    messages.exception_error_message,
    onError,
    orderRef,
    scope,
  ]);

  return {
    qrCodeData,
    bankIdFeedback,
    redirectToBankIdApp,
    onUseQrCode,
    onUseBankIdApp,
    onCancelBankId,
  };
};
