import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { deleteProductDetails } from "@app/services/products/deleteProductDetails";
import { fetchAllProducts } from "@app/services/services/fetchProductById";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

import { formatData, getConfig } from "./utils";
import { useHistory } from "react-router-dom";

const useProductDetails = ({
  productId,
  data,
  dataSourceTypes,
  legalFrameworks,
  onUpdateService,
}) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();

  const [caseProducts, setCaseProducts] = useState([]);
  const [servicesInBundle, setServicesInBundle] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const hasProductsPermission = hasAllPermissions([
    AdminPermissions.AdminWriteProducts,
  ]);

  const hasDeleteAccessToProducts = hasAllPermissions([
    AdminPermissions.AdminDeleteProducts,
  ]);

  useEffect(() => {
    const fetchCaseProducts = async () => {
      try {
        setIsLoading(true);

        const { data: { data: productsData = [] } = {} } =
          await fetchAllProducts({ type: "service" });
        const filteredServices = productsData.map(({ id, name }) => ({
          name,
          label: name,
          code: id,
        }));

        setCaseProducts(productsData);
        setServicesInBundle(filteredServices);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCaseProducts();
  }, [messages.exception_error_message, showErrorToast]);

  const formattedData = formatData(
    data,
    dataSourceTypes,
    legalFrameworks,
    caseProducts,
    messages,
    servicesInBundle
  );

  const { details, description, configuration, addOnProducts } = getConfig(
    formattedData,
    caseProducts,
    servicesInBundle
  );

  const handleUpdateService = values => {
    onUpdateService(values);
  };

  const handleUpdateConfiguration = ({
    is_case,
    is_report,
    add_on_products,
    ...values
  }) => {
    const payload = {
      ...values,
      is_case: Number(is_case),
      is_report: Number(is_report),
      add_on_products: add_on_products?.map(id => id),
    };

    handleUpdateService(payload);
  };

  const handleDeleteSubmit = async () => {
    try {
      await deleteProductDetails({
        productId: productId,
      });

      showSuccessToast(messages.product_deleted_successful);

      history.push(ROUTES.ADMIN_PRODUCTS_LIST.URL);
      return;
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  return {
    config: { details, description, configuration, addOnProducts },
    hasPermission: hasProductsPermission,
    hasDeleteAccessToProducts,
    isLoading,
    productData: formattedData,
    handleUpdateService,
    handleUpdateConfiguration,
    handleDeleteSubmit,
  };
};

useProductDetails.propTypes = {
  productId: PropTypes.string,
  data: PropTypes.object,
  dataSourceTypes: PropTypes.array,
  legalFrameworks: PropTypes.array,
  onUpdateService: PropTypes.func,
};

export default useProductDetails;
